import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { getCookie } from '../../util/util.js';

import Selector2 from '../buttons/Selector2';
import ChargerChasItem from '../list/ChargerChasItem';

const ChasSettingModal = (props) => {
  let signedToken = getCookie("signedToken");
  const [tabIdx, setTabIdx] = useState(0);
  const[chasInfo, setChasInfo] = useState();

  const[checkList, setCheckList] = useState([]);

  useEffect(()=>{
    let cpoId = localStorage.getItem('cpoId');
    getActionSetList(cpoId);
  },[])

  const getActionSetList = async(id)=>{
    await axios.get(`/controlhub/api/cpouser/v1/ActionSets`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      setChasInfo([...res.data]);
    })
    .catch((err)=>{
      
    })
    
  }

  function RenderTab(props){
    return [ 
    <ChasSettingTab1 setSelectedStation={props.setSelectedStation} setCheckList={setCheckList} setTabIdx={setTabIdx} selectedStation={props.selectedStation === null ? 0:props.selectedStation} stationInfo={props.stationInfo} chargerInfo={props.chargerInfo} chargerHubInfo={props.chargerHubInfo}/>, 
    <ChasSettingTab2 getChargerHubList={props.getChargerHubList} setOnOff={props.setOnOff} checkList={checkList} chasInfo={chasInfo} setTabIdx={setTabIdx} selectedStation={props.selectedStation === null ? 0:props.selectedStation} stationInfo={props.stationInfo} chargerInfo={props.chargerInfo} chargerHubInfo={props.chargerHubInfo}/> 
    ][props.idx]
  }
  
  //selectedStation stationInfo chargerInfo chargerHubInfo
  return (
    <div className="cpo-chas-setting-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-chas-setting-modal-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-chas-setting-modal-header">
          <title>CAHS 설정</title>
          <p>{tabIdx == 0 ? "설정할 충전기를 선택해주세요":"충전기 별 CHAS를 설정해주세요"}</p>
        </div>
        <RenderTab getChargerHubList={props.getChargerHubList} setSelectedStation={props.setSelectedStation} setOnOff={props.setOnOff} idx={tabIdx} selectedStation={props.selectedStation === null ? 0:props.selectedStation} stationInfo={props.stationInfo} chargerInfo={props.chargerInfo} chargerHubInfo={props.chargerHubInfo}/>
      </div>
    </div>
  )
}
export default ChasSettingModal;

const ChasSettingTab1 = (props)=>{
  const [station, setStation] = useState(props.stationInfo[props.selectedStation === null ? 0:  props.selectedStation]);
  const [stationActive, setStationActive] = useState(null);
  const activeReset = ()=>{
    setStationActive(false);
  }
  useEffect(()=>{
    props.setSelectedStation(props.stationInfo.indexOf(station));
  },[station])

  // check한 station list
  const [checkChargerList, setCheckChargerList] = useState([]);
  // checked state (모든 아이템 check)
  const [checked, setChecked] = useState(false);
  useEffect(()=>{
    if(checked){
      getAllCheckArray();
    }
    else{
      setCheckChargerList([]);
    }
  },[checked])
  let getAllCheckArray = async()=>{
    let temp = new Array(props.chargerInfo[props.selectedStation === null ? 0:  props.selectedStation].Total).fill(0)
    let idx = 0;
    for await(const i of temp){
      temp[idx] = idx++;
    }
    setCheckChargerList(temp);
  }
  return(
    <>
      <div className="cpo-chas-setting-content-container">
        <div className='cpo-chas-setting-content-top'>
          <div>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon"></span>
            </label>
            <p>전체</p>
          </div>
          <div>
            <p>충전소</p>
            <div className='cpo-chas-setting-content-selector-container'>
              <Selector2 name={'ChargingStationName'} dataList={props.stationInfo} select={station} setSelect={setStation} active={stationActive} setActive={setStationActive} activeReset={activeReset}/>
            </div>
          </div>
        </div>
        <div className='cpo-chas-setting-content-middle'>
          {
            props.chargerHubInfo[props.selectedStation === null ? 0:  props.selectedStation].map((it, idx)=>{
              return(
                <>
                  <ChargerChasItem key={'stationItem-'+idx} setSelectedChasInfo={()=>{}} checkChargerList={checkChargerList} setCheckChargerList={setCheckChargerList} idx={idx} name={props.chargerInfo[props.selectedStation === null ? 0:props.selectedStation].Results[idx].DisplaySerialNumber}  data={it} />
                </>
              )
            })
          }
        </div>
        <div className='cpo-chas-setting-content-bottom'>
          <div></div>
          <div className={'cpo-chas-setting-content-button ' + `${checkChargerList.length == 0 ? "none":""}`} onClick={(e)=>{e.stopPropagation();e.preventDefault();props.setTabIdx(1); let temp = [...checkChargerList];temp.sort(); props.setCheckList(temp);}}><p>다음</p></div>
        </div>
      </div>
    </>
  );
}
const ChasSettingTab2 = (props)=>{
    let signedToken = getCookie("signedToken");
    // chasInfo
  const [chas, setChas] = useState("");
  const [chasActive, setChasActive] = useState(null);
  const activeReset = ()=>{
    setChasActive(false);
  }

  const [selectedChasInfo, setSelectedChasInfo] = useState({});

  const putActionSetList = async(id)=>{
    for await(const item of props.checkList){
      // console.log("chargerId : " +props.chargerHubInfo[props.selectedStation === null ? 0:  props.selectedStation][item].chargerId);
      // console.log("actionSetId : "+selectedChasInfo[item].actionSetId);
      await axios.put(`/controlhub/api/cpouser/v1/Chargers/${props.chargerHubInfo[props.selectedStation === null ? 0:  props.selectedStation][item].chargerId}/ActionSet`,{
        "actionSetId": parseInt(selectedChasInfo[item].actionSetId)
      },{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      },)
      .then((res)=>{
      })
    }
    props.getChargerHubList();
    props.setOnOff(false);
  }
  return(
    <>
      <div className="cpo-chas-setting-content-container">
        <div className='cpo-chas-setting-content-top'>
          <div>
          </div>
          <div>
            <p>일괄 설정</p>
            <div className='cpo-chas-setting-content-selector-container'>
              <Selector2 name={'name'} dataList={props.chasInfo} select={chas} setSelect={setChas} active={chasActive} setActive={setChasActive} activeReset={activeReset}/>
            </div>
          </div>
        </div>
        <div className='cpo-chas-setting-content-middle' onClick={()=>{console.log(selectedChasInfo)}}>
          {
            props.checkList.map((i, idx)=>{
              let it = props.chargerHubInfo[props.selectedStation === null ? 0:  props.selectedStation][i]
              return(
                <>
                  <ChargerChasItem setParentActive={setChasActive} parentActive={chasActive} key={idx} chasIdx={i} selectedChasInfo={selectedChasInfo} setSelectedChasInfo={setSelectedChasInfo} chasInfo={props.chasInfo} chasBatch={chas} checkOff={true} idx={idx} name={props.chargerInfo[props.selectedStation === null ? 0:  props.selectedStation].Results[i].DisplaySerialNumber}  data={it} />
                </>
              )
            })
          }
        </div>
        <div className='cpo-chas-setting-content-bottom'>
          <div className='cpo-chas-setting-content-button' onClick={(e)=>{e.stopPropagation();e.preventDefault();props.setTabIdx(0)}}><p>이전</p></div>
          <div className={'cpo-chas-setting-content-button'} onClick={(e)=>{e.stopPropagation();e.preventDefault();
            putActionSetList();
            // props.setOnOff(false);
            }}><p>적용하기</p></div>
        </div>
      </div>
    </>
  );
}
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';

export const ChargingFeeModalStationItem = (props) => {
  let signedToken = getCookie("signedToken");

  const rateInput = (target)=>{
    target.value = target.value.replace(/[^0-9]/g, '');
  }

  const [checked, setChecked] = useState(false);
  return (
    <div className='charging-fee-modal-charger-item'>
      <div>
        <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
          <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
          <span className="checkbox_icon"></span>
        </label>
      </div>
      <div><p>{props.data.ChargingStationName}</p></div>
      <div>
        <div className="charging-fee-status-badge">
          <p>{props.data.isSale ? "할인중":props.data.iFree ? "무료":"기본가적용중"}</p>
        </div>
      </div>
      <div><p>{timeConverter(props.data.freePeriodStart, props.data.freePeriodEnd)}</p></div>
      <div><p>{timeConverter(props.data.salePeriodStart, props.data.salePeriodEnd)}</p></div>
    </div>
  )
}
export default ChargingFeeModalStationItem;


const timeConverter = (start, end)=>{
  if(!start&& !end ){
    return '설정된 기간 없음';
  }
  else{
    return `${start} ~ ${end}`;
  }
}
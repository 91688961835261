import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import { setCookie, getCookie, timeInput, timestampFormat2, dateFormat } from '../../util/util';
import { TfiClose } from "react-icons/tfi";

import { JsonView, allExpanded, darkStyles, defaultStyles, collapseAllNested } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { getChargerInfo } from '../../api/infra/infraApi';

export const CpoLogDetailModal = (props) => {
  const signedToken =  getCookie('signedToken')

  useEffect(()=>{
    reqChargerInfo(props.data.charger.chargerId);
    console.log(props.data);
  },[])

  const reqChargerInfo = async(id)=>{
    setChargerInfo(await getChargerInfo(id));
  }
  const [chargerInfo, setChargerInfo] = useState();
  return (
    <div className="cpo-log-report-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-log-report-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-log-report-modal-header">
          <title>상세정보</title>
          <p>메세지 상세정보</p>
          <div className='cpo-log-report-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>

        <div className="cpo-log-report-modal-content ">
          <div className="cpo-log-detail-info-container">
            <div className='cpo-log-detail-info-row'>
              <p>충전소 이름</p>
              <p>{chargerInfo?.ChargingStation.ChargingStationName}</p>
            </div>
            <div className='cpo-log-detail-info-row grid'>
              <div>
                <p>메시지 유형</p>
                <div className={"cpo-log-report-messageType "+`${props.data.messageType == "CALL"?"request": props.data.messageType == "CALLRESULT"? "confirm":props.data.messageType == "CALLERROR"?"":"error"}`}><p>{props.data.messageType == "CALL"? "REQUEST" :props.data.messageType == "CALLRESULT"? "CONFIRM" :props.data.messageType == "CALLERROR"? "ERROR" :"-"}</p></div>
              </div>
              <div>
                <p>액션명</p>
                <p>{props.data.action}</p>
              </div>
            </div>
            <div className='cpo-log-detail-info-row grid'>
              <div>
                <p>충전기 식별번호</p>
                <p>{props.data.charger.displaySerialNumber}</p>
              </div>
              <div>
                <p>통신 날짜</p>
                <p>{timestampFormat2(new Date(props.data.timestamp))}</p>
              </div>
            </div>
          </div>
          <p className='cpo-log-report-modal-content-title'>Data Payload</p>
          <div className="cpo-log-detail-json-container json-container">
            <JsonView data={JSON.parse(props.data.payload)} shouldExpandNode={collapseAllNested}clickToExpandNode={true} style={darkStyles} />
          </div>
        </div>

        


      </div>
    </div>
  )
}
export default CpoLogDetailModal




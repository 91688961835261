import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate, useLocation } from "react-router-dom";
import CHASItem from '../../components/list/CHASItem.js';
import { MdPlaylistAdd } from "react-icons/md";
import { getCookie, refresh } from '../../util/util.js';
import Selector2 from '../../components/buttons/Selector2.js';
import { MdSearch } from "react-icons/md";
import { FaRegQuestionCircle } from "react-icons/fa";
import { FaRegTrashAlt, FaChevronLeft } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import { GrTransaction } from "react-icons/gr";

import ChasDetailModal from '../../components/modals/ChasDetailModal.js';
import CpoChasEdit from './CpoChasEdit.js';
import ChasDuplicationModal from '../../components/modals/ChasDuplicationModal.js';
import ChasAppendModal from '../../components/modals/ChasAppendModal.js';
import ChasDeleteModal from '../../components/modals/ChasDeleteModal.js';

const CpoCHAS = () => {
  let navigator = useNavigate();
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");
  // 진입 시 redux에 page 적용  
  useEffect(()=>{
    dispatch(setNum({id:7, idx:1}));
  },[])

  

  // /controlhub/api/ActionSets?cpoId=monit

  const [CHASList, setCHASList] = useState([]);
  const [ConnectionList, setConnectionList] = useState([]);
  const [ConnectionKeys, setConnectionKeys] = useState([]);
  const getCHASList = async()=>{
    await axios.get(`/controlhub/api/cpouser/v1/ActionSets`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      setCHASList([...res.data]);
      let selectedChas = localStorage.getItem("chasSelect");
      let chasCategory = localStorage.getItem('chasCategory');
      console.log(chasCategory);
      if(selectedChas){
        setChasSelect([...res.data].filter(it=>it.name == selectedChas)[0])
      }
      if(chasCategory == 'normal' || chasCategory == 'data'){
        setCategory(chasCategory);
      }
    })
    .catch((err)=>{
      
    })
    
  }
  useEffect(()=>{
    getCHASList();
  },[])

  // actionset 클릭 index
  const [clickedActionSet, setClickedActionSet] = useState(0);

  const getConnectionList = async(actionSetId)=>{
    await axios.get(`/controlhub/api/cpouser/v1/ActionSets/${actionSetId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data);
      
      let connectionListTemp = [...res.data.messageControlHubActions];
      let msgTemp = {};

      for (const item of connectionListTemp){
        // 메세지 종류 확인
        let msg = '';
        if(item.messageType == 'DATA_TRANSFER_REQ'){
          msg = item.messageType + "_" + item.dataTransferMessageId;
        }
        else{
          msg = item.messageType
        }

        // 메세지 종류 == key 인 object 생성
        if(!(msgTemp[msg])){
          msgTemp[msg] = [item];
        }
        else{
          msgTemp[msg] = [...msgTemp[msg],item];
        }
      }
      console.log(msgTemp);
      setConnectionList(msgTemp);
      setConnectionKeys(Object.keys(msgTemp));
    })
    .catch((err)=>{
      
    })
  }

  const [chasSelect, setChasSelect] = useState();
  const [chasActive, setChasActive] = useState();

  const activeReset = ()=>{
    setChasActive(false);
  }
  const [chasDataModalOn, setChasDataModalOn] = useState(false);
  useEffect(()=>{
    if(!chasSelect){
      setChasDataModalOn(false)
    }
  },[chasSelect])


  const [category, setCategory] = useState('normal');

  const [selectedMsg, setSelectedMsg] = useState();

  const [detailModalOn, setDetailModalOn] = useState(false);
  const [detailModalContent, setDetailModalContent] = useState(null);
  
  const [duplicationModalOn, setDuplicationModalOn] = useState(false);
  const [appendModalOn, setAppendModalOn] = useState();
  const [deleteModalOn, setDeleteModalOn] = useState();
  const [deleteModalContent, setDeleteModalContent] = useState();
  const [deleteModalData, setDeleteModalData] = useState();


  useEffect(()=>{
    if(chasSelect){
      localStorage.setItem("chasSelect",chasSelect?.name)
    }
  },[chasSelect])
  useEffect(()=>{
    if(chasSelect){
      getConnectionList(chasSelect?.actionSetId)
      setSelectedMsg("")
    }
  },[chasSelect])

  

  return (
    <div className='cpo-system-setting-container'>
      {detailModalOn && <ChasDetailModal setOnOff={setDetailModalOn} data={detailModalContent}/>}
      {duplicationModalOn && <ChasDuplicationModal setOnOff={setDuplicationModalOn} data={CHASList}/>}
      {appendModalOn && <ChasAppendModal setOnOff={setAppendModalOn} getCHASList={getCHASList}/>}
      {deleteModalOn && <ChasDeleteModal setOnOff={setDeleteModalOn} content={deleteModalContent} data={deleteModalData} getCHASList={getCHASList}/>}

      <div className="cpo-system-setting-inner chas">
        <div className="cpo-chas-setting-header">
          <div className="cpo-chas-selector-container">
            <Selector2 maxCount={5} name={'name'} dataList={CHASList} select={chasSelect} setSelect={setChasSelect} active={chasActive} setActive={setChasActive} activeReset={activeReset}/>
          </div>
          <div className="cpo-charger-management-keyword-search-container">
            <input type="text" placeholder='키워드로 검색하기' onClick={(e)=>{e.stopPropagation(); }}/>
            <button><MdSearch className='search-icon'/></button>
          </div>
          <div className='cpo-chas-button-container'>
            <div onClick={()=>{setDuplicationModalOn(true)}}>복제</div>
            <div onClick={()=>{setAppendModalOn(true);}}>CHAS 추가</div>
          </div>
        </div>
        <div className="cpo-chas-content-container">
          <div className="cpo-chas-content-category-info">{category=="normal"?"일반 메세지":"DataTransfer 메세지"}</div>
          <div className='cpo-chas-content-header'>
            <div className='cpo-chas-data-container'><p>CHAS DATA</p><FaRegQuestionCircle onClick={()=>{console.log(chasSelect); setChasDataModalOn(!chasDataModalOn);}}/></div>
            {(chasDataModalOn && chasSelect)?
              <div className='cpo-chas-data-modal'>
                <p>{chasSelect?.description}</p>
                <p>{`${chasSelect?.createdDate} / ${chasSelect?.lastModifiedDate} / ${'1.0.0'} / ${'0.0.0'}`}</p>
              </div>
              :<></>
            }

            <div className='cpo-chas-title-container'>
              {
                chasSelect?
                  <>
                    <p>{chasSelect?.name}</p>
                    <div className='cpo-chas-title-function-container'>
                      <div>
                        <div className="cpo-chas-title-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); }}>
                          <div><FiEdit3/></div>
                        </div>
                      </div>

                      <div>
                        <div className="cpo-chas-title-function-button delete" onClick={(e)=>{e.stopPropagation(); e.preventDefault();setDeleteModalData({action:chasSelect}); setDeleteModalContent("actionset"); setDeleteModalOn(true)}}>
                          <div><FaRegTrashAlt/></div>
                        </div>
                      </div>
                    </div>
                  </>
                :
                <>
                  <p className='cpo-chas-title-select-msg'>CHAS를 선택해주세요.</p>
                </>
              }
            </div>
            {
              chasSelect ?
                !selectedMsg ?
                <>
                  <div className='cpo-chas-management-button' onClick={()=>{if(category == 'normal'){navigator('./edit/normal',{ state: { data: chasSelect} } );}else{navigator('./edit/data-transfer', { state: { data: chasSelect, idData: ConnectionKeys} })}}}>
                    <p>메세지 추가</p>
                  </div>
                  <div className='cpo-chas-transfer-button' onClick={()=>{let temp; if(category == 'normal'){temp = 'data'}else{temp='normal'} setCategory(temp); localStorage.setItem('chasCategory',temp)}}>
                    <GrTransaction/>
                    <p>{category == 'normal' ?'DataTransfer 메세지':"일반 메세지"}</p>
                  </div>
                </>
                :
                <div className='cpo-chas-management-button' onClick={()=>{if(category == 'normal'){navigator('./edit/normal',{ state: { data: chasSelect, message:selectedMsg} } );}else{navigator('./edit/data-transfer', { state: { data: chasSelect, idData: ConnectionKeys, message:selectedMsg}, })}}}>
                  <p>액션셋 편집</p>
                </div>
              :<></>
            }

          </div>

          {
            !selectedMsg?

            <div className='cpo-chas-item-list'>
              {
                chasSelect ?
                  ConnectionKeys.map((it, idx)=>{
                    if(category=='normal' && it.startsWith("DATA_TRANSFER"))return;
                    if(category=='data' && !it.startsWith("DATA_TRANSFER"))return;
                    return(
                      <div onClick={()=>{setSelectedMsg(it)}}>
                        <p>{it}</p>
                      </div>
                    )
                  })
                  
                :
                  <></>
              }
              
            </div>
            :
            <div className='cpo-chas-message-detail-container'>
              <div className='cpo-chas-message-header' onClick={()=>{setSelectedMsg(null)}}>
                <FaChevronLeft/>
                <p>{selectedMsg}</p>
                <FaRegTrashAlt className='cpo-chas-message-delete-button' onClick={(e)=>{e.stopPropagation(); setDeleteModalContent("message"); setDeleteModalData({action:chasSelect,message:{name:selectedMsg, map:ConnectionList[selectedMsg]}}); setDeleteModalOn(true); }}/>
              </div>
              <div className='cpo-chas-message-detail-column'>
                <p>순서</p>
                <p>actoin명</p>
                <p>설명</p>
              </div>
              {
                ConnectionList[selectedMsg]?.map((it, idx)=>{
                  return(
                    <div className='cpo-chas-action-item'>
                      <div className='cpo-chas-action-list-cell'><p>{it.order}</p></div>
                      <div className='cpo-chas-action-list-cell'><p>{it.chControlHubAction.name}</p></div>
                      <div className='cpo-chas-action-list-cell'><p>{it.chControlHubAction.description}</p></div>
                      <div className={'cpo-chas-action-list-cell '+`${it.public ? "private":""}`}><div><p></p></div></div>
                      <div className='cpo-chas-action-list-cell more'><p onClick={()=>{setDetailModalContent(it);setDetailModalOn(true)}}>+ more</p></div>
                    </div>
                  )
                })
              }

            </div>
          }


        </div>
        {/* <div className="cpo-chas-conatiner">
          <div className='cpo-chas-actionset-column'><p>ActionSet 선택</p></div>
          <div className='cpo-chas-connection-column'><div><title>{CHASList[clickedActionSet]?.name}</title><desc>{CHASList[clickedActionSet]?.description}</desc></div><div className='cpo-chas-connection-add-button'><p>연결 추가</p></div></div>
          <div className='cpo-chas-actionset-list-container'>
            {
              CHASList.map((it, idx)=>{
                return(
                  <div className={'cpo-chas-actionset-item ' +`${clickedActionSet == idx ? 'selected' : ""}`} onClick={()=>{setClickedActionSet(idx)}}>{it.name}</div>
                )
              })
            }
            <div className='cpo-chas-actionset-add-button'><MdPlaylistAdd/><p>ActionSet 추가</p></div>
          </div>
          <div className='cpo-chas-connection-list-container'>
            {
              ConnectionKeys.map((it, idx)=>{
                return(
                  <CHASItem data={ConnectionList[it]} title={it} clickedActionSet={clickedActionSet}/>
                )
              })
            }
          </div>
        </div> */}
      </div>
    </div>
  )
}
export default CpoCHAS;
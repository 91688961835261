import React, { useRef, useState, useEffect } from 'react';
import { MdSearch } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { getCookie } from '../../util/util.js';

import Carousel from '../../components/carousel/Carousel';
import ChargerCard from '../../components/list/ChargerCard.js';
import ChasSettingModal from '../../components/modals/ChasSettingModal.js';
import CpoChargerCardEventModal from '../../components/modals/CpoChargerCardEventModal.js';
// 충전기 운용제어
const CpoChargerControl = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  // 선택된 충전소 idx
  const [selectedStation, setSelectedStation] = useState(null);
  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  // 충전기 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);

  //TODO 계정으로부터 받아올 데이터 (cpo id)
  
  let cpo = useSelector((state) => state.cpo );

  // let cpo = 'f296c394-8e67-4c83-9cff-3240c3286e8d';

  //페이지 진입 시 redux에 페이지 적용
  useEffect(()=>{
    dispatch(setNum({id:2, idx:0}));
    console.log(cpo);
    let cpoId = localStorage.getItem('cpoId');
    getStationList(cpoId);
  },[])
  
  // cpo가 소유한 station GET
  const getStationList = async(id)=>{  
    await axios.get(`/infra/api/cpouser/v1/ChargingStations`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      let temp = [...res.data.Results.filter(it=>it.OpStatus!=="삭제됨")];
      setStationInfo(temp);
    })
    .catch(()=>{})
  }

// chargerHub 정보 API GET
const getChargerHubList = async()=>{
  let temp = [];
  for await(const it of stationInfo){
    await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${it.ChargingStationId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
    .catch(()=>{})
  }
  console.log(temp);
  setChargerHubInfo(temp);
}

// charger 정보 API GET
const getChargerList = async()=>{
  let temp = [];
  for await(const it of stationInfo){
    await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${it.ChargingStationId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
    .catch(()=>{})
  }
  console.log(temp);
  setChargerInfo(temp);
}

  // station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
  useEffect(() => {
    if(!stationInfo)return;
    getChargerHubList();
    getChargerList();
  }, [stationInfo])
  
  // search input ref
  const searchInputRef = useRef(null);
  // 검색 function
  const submitSearch= async(e)=>{
    e.preventDefault();
    console.log(searchInputRef.current.value);
  }
  
  const [modalOn, setModalOn] = useState(false);
  const search = (val)=>{
    setSelectedStation(stationInfo.indexOf(stationInfo.filter(it=>it.ChargingStationName == val)[0]));
  }
  const searchClick = ()=>{

    const val = document.getElementById('searchInput').value;
    const opts = document.getElementById('searchOptions').children;
    for (var i = 0; i < opts.length; i++) {
      if (opts[i].value === val) {
        search(opts[i].value);
        break;
      }
    }
  }

  const [chargerEventModalOn, setChargerEventModalOn] = useState(false);
  const [chargerEventModalIdx, setChargerEventModalIdx] = useState(null);
  const [chargerEventModalData, setChargerEventModalData] = useState(null);
  return (
    <div className='cpo-charger-control-container'>
      {chargerEventModalOn&&<CpoChargerCardEventModal getChargerList={getChargerList} data={chargerEventModalData}  idx={chargerEventModalIdx} setOnOff={setChargerEventModalOn}/>}
      <div className="cpo-charger-control-wrapper">
        {modalOn && chargerHubInfo && stationInfo && chargerInfo ? <ChasSettingModal getChargerHubList={getChargerHubList} setOnOff={setModalOn} selectedStation={selectedStation} setSelectedStation={setSelectedStation} stationInfo={stationInfo} chargerInfo={chargerInfo} chargerHubInfo={chargerHubInfo}/>:<></>}
        <div className="cpo-charger-control-header">
          <p>
            나의 충전소
          </p>
          <div className="nav-input-container">
            <input id='searchInput' list='searchOptions' autocomplete='on' ref={searchInputRef} type="text" onInput={()=>{searchClick()}} placeholder='충전소를 검색하세요' onKeyDown={(e)=>{if(e.key == "Enter")search(e.target.value)}}/>
            <MdSearch className='search-icon'/>
          </div>
          <datalist id='searchOptions'>
            {stationInfo?.map((it,idx)=>{
              return(<option value={it.ChargingStationName}/>)
            })}
          </datalist>
        </div>
        {
          stationInfo && chargerInfo &&
          <Carousel items={stationInfo} selected={selectedStation} setSelected={setSelectedStation}/>
        }
        <div className="cpo-charger-control-header second">
          <p onClick={()=>{console.log(selectedStation, stationInfo, chargerInfo, chargerHubInfo)}}>
            충전기
          </p>
          <div className='cpo-charger-chas-setting-open-button' onClick={()=>{setModalOn(true)}}><p>CHAS 설정</p></div>
        </div>
        <div className="cpo-charger-control-device-container">
          {
            selectedStation==undefined?
              <div className='plz-select-msg'><p>충전소를 선택해주세요.</p></div>
            : chargerInfo[selectedStation]?.Results.map((it, idx)=>{
              if(it.IsDeleted !== true){
                return(
                  <ChargerCard setChargerEventModalData={setChargerEventModalData} setChargerEventModalOn={setChargerEventModalOn} setChargerEventModalIdx={setChargerEventModalIdx} getChargerHubList={getChargerHubList} getChargerList={getChargerList} data={it} key={idx} controlhubData={chargerHubInfo[selectedStation][idx]}/>
                );
              }
              else{
                return(<></>);
              }
            })
          }
        </div>
      </div>
    </div>
  );
};

export default CpoChargerControl;

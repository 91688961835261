import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import { GrConnectivity } from "react-icons/gr";
import { RiOutlet2Line } from "react-icons/ri";
import { FaBoltLightning, FaArrowRotateLeft } from "react-icons/fa6";
import { TbChevronCompactRight } from "react-icons/tb";
import { GrTest, GrUpgrade } from "react-icons/gr";
import { TbReportAnalytics } from "react-icons/tb";
import { RiDeleteBin2Line } from "react-icons/ri";
import { FaStop } from "react-icons/fa";
import { GrCircleInformation } from "react-icons/gr";
import { MdPerson } from "react-icons/md";
import { FaRegChartBar } from "react-icons/fa6";
import { FiTrash2 } from "react-icons/fi";

import {chargerStatusConverter, chargerTypeConverter, connectorTypeConverter} from "../../util/util.js";
import { getCookie } from '../../util/util.js';
import { deleteCharger } from '../../api/infra/infraApi.js';

const ChargerCard = (props) => {
  let navigator = useNavigate();

  const [flip, setFlip] = useState(false);
  const [chargerStatus, setChargerStatus] = useState("");
  let signedToken = getCookie("signedToken");
  
  useEffect(()=>{
    switch (props.controlhubData?.status) {
      case 'NotUsed':setChargerStatus(""); break;
      case 'ReadyForConnection':setChargerStatus("ready"); break;
      case 'Connected':setChargerStatus("connected"); break;
      case 'Available':setChargerStatus("available"); break;
      case 'Preparing':setChargerStatus("preparing"); break;
      case 'Charging':setChargerStatus("charging"); break;
      case 'SuspendedEVSE':setChargerStatus("pause"); break;
      case 'SuspendedEV':setChargerStatus("pause"); break;
      case 'Finishing':setChargerStatus("finishing"); break;
      case 'Reserved':setChargerStatus("reserved"); break;
      case 'Unavailable':setChargerStatus("unavailable"); break;
      case 'Faulted':setChargerStatus("faulted"); break;
      case 'Deleted':setChargerStatus(""); break;
      default:setChargerStatus("");break;
    }
  },[props.controlhubData?.status])


  const chargerStartStop = async(idx)=>{
    {
      idx == 0?
      await axios.put(`/controlhub/api/cpouser/v1/Chargers/${props.data.ChargerId}/Used`,
      {
        "use": false
      }, 
      {
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      }
      ).then((res)=>{
        props.getChargerHubList();
        props.getChargerList();
      })
      :
      await axios.put(`/controlhub/api/cpouser/v1/Chargers/${props.data.ChargerId}/Used`,
      {
        "use": true
      }, 
      {
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      }
      ).then((res)=>{
        props.getChargerHubList();
        props.getChargerList();
      })
    }
    
  }


  const [tabIdx, setTabIdx] = useState(0)
  return (
    <div className={"cpo-charger-card-container "}>
      {
        tabIdx == 0 ?

        <div className='cpo-charger-item-container front'>
          <div className="cpo-charger-status-container">
            {/* 충전소일련번호 API 업데이트 시 적용 */}
            <p className='cpo-charger-id'>{props.data.DisplaySerialNumber}</p>
            <div className={'cpo-charger-status '+`${chargerStatus}`}>
              <p className={'cpo-charger-status-content '}>{chargerStatusConverter(props.controlhubData?.status)}</p>
            </div>
          </div>
          <div className="cpo-charger-front-container">
            <div className="cpo-charger-front-title">
              <p>가동일수</p>
            </div>
            <p className='cpo-charger-front'>
              {props.data.ChargerModel ? chargerTypeConverter(props.controlhubData?.ChargerType)  : '정보없음'}
            </p>
          </div>
          <div className="cpo-charger-front-container">
            <div className="cpo-charger-front-title">
              <p>액션셋</p>
            </div>
            <p className='cpo-charger-front'>
              {props.controlhubData?.actionSetName ? props.controlhubData?.actionSetName  : '정보없음'}
            </p>
          </div>
          <div className="cpo-charger-front-container">
            <div className="cpo-charger-front-title">
              <p>OCCP 버전</p>
            </div>
            <p className='cpo-charger-front'>
              {props.data.Protocol ? props.data?.Protocol  : '정보없음'}
            </p>
          </div>

          <div className="cpo-charger-front-container">
            <div className="cpo-charger-front-title">
              <p>버전 정보</p>
            </div>
            <p className='cpo-charger-front'>
              {props.data.ChargerModel ? connectorTypeConverter(props.data.ChargerModel?.ConnectorType) : '정보없음'}
            </p>
          </div>
          <div className="cpo-charger-btn-list-container">

            <div className='cpo-charger-onOff-btn-container' onClick={(e)=>{e.stopPropagation(); console.log(props.controlhubData); 
              props.controlhubData?.used?chargerStartStop(0):chargerStartStop(1)
            }}>
              
              <div className={'cpo-charger-onOff-btn ' +`${props.controlhubData?.used?" used":""}`}>
                <div className="cpo-charger-onOff-btn-icon">
                  {
                    props.controlhubData?.used?
                    <FaStop/>
                    :
                    <FaBoltLightning/>
                  }
                </div>
                <p>
                  {props.controlhubData?.used?"운용중지":"운용시작"}
                </p>
              </div>
            </div>

            <div className='cpo-charger-reset-btn-container' onClick={(e)=>{e.stopPropagation();}}>
              <div className='cpo-charger-onOff-btn'>
                <div className="cpo-charger-onOff-btn-icon">
                  <FaArrowRotateLeft/>
                </div>
                <p>재시동</p>
              </div>
            </div>

          </div>
        </div>
        :
        <div className='cpo-charger-item-container back'>
          <div className="cpo-charger-status-container">
            {/* 충전소일련번호 API 업데이트 시 적용 */}
            <p className='cpo-charger-id'>{props.data.DisplaySerialNumber}</p>
            <div className={'cpo-charger-status '+`${chargerStatus}`}>
              <p className={'cpo-charger-status-content '}>{chargerStatusConverter(props.controlhubData?.status)}</p>
            </div>
          </div>
          <div className="cpo-charger-four-btn-container" onClick={(e)=>{e.stopPropagation();}}>

            <div className='cpo-charger-back-btn-container' onClick={()=>{props.setChargerEventModalOn(true); props.setChargerEventModalIdx(0); props.setChargerEventModalData(props.data);}}>
              <GrCircleInformation/>
              <p>기기사양 보기</p>
            </div>
            <div className='cpo-charger-back-btn-container' onClick={()=>{props.setChargerEventModalOn(true); props.setChargerEventModalIdx(1)}}>
              <MdPerson />
              <p>담당자 보기</p>
            </div>
            <div className='cpo-charger-back-btn-container' onClick={()=>{props.setChargerEventModalOn(true); props.setChargerEventModalIdx(2)}}>
              <GrTest/>
              <p>연결 테스트</p>
            </div>
            <div className='cpo-charger-back-btn-container' onClick={()=>{props.setChargerEventModalOn(true); props.setChargerEventModalIdx(3)}}>
              <GrUpgrade />
              <p>펌웨어 업데이트</p>
            </div>
            <div className='cpo-charger-back-btn-container' onClick={()=>{props.setChargerEventModalOn(true); navigator("/cpo/pay-report",{ state: { DisplaySerialNumber: props.data.DisplaySerialNumber} })}}>
                <FaRegChartBar />              
              <p>로그 조회</p>
            </div>
            <div className='cpo-charger-back-btn-container delete' onClick={()=>{props.setChargerEventModalOn(true); props.setChargerEventModalIdx(5); props.setChargerEventModalData(props.data);}}>
              <FiTrash2 />
              <p>삭제</p>
            </div>
          </div>
        </div>
      }
      <div className='cpo-charger-card-idx-control-container'>
        <div className={`${tabIdx == 0 ? "selected":""}`} onClick={()=>{setTabIdx(0)}}></div>
        <div className={`${tabIdx == 1 ? "selected":""}`} onClick={()=>{setTabIdx(1)}}></div>
      </div>
    </div>
  )
}
export default ChargerCard;
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import SearchSelectorNotCreatable from '../buttons/SearchSelectorNotCreatable';
import Toggle from '../buttons/Toggle';



const ChargingFeeSearchModal = (props)=>{
  const regionList = [
    {value: 'A(서울, 인천, 경기)', label: 'A(서울, 인천, 경기)', idx:0},
    {value: 'B(충청, 전라)', label: 'B(충청, 전라)', idx:1},
    {value: 'C(강원, 경상)', label: 'C(강원, 경상)', idx:2},
    {value: 'D(제주)', label: 'D(제주)', idx:3},
  ];
  const [dataLabel, setDataLabel] = useState();
  const [isFree, setIsFree] = useState();
  const [isSale, setIsSale] = useState();
  
  const send = async()=>{
    
  }

  return(
    <>
      <div className={'cpo-charging-fee-search-modal-container'} onClick={(e)=>{e.stopPropagation();}}>
        <div className='cpo-charging-fee-search-modal-row'>
          <p>권역  일괄 설정</p>
          <SearchSelectorNotCreatable options={regionList} placeholder="권역을 선택하세요"  value={dataLabel} setValue={setDataLabel}/>
        </div>
        <div className='cpo-charging-fee-search-modal-row'>
          <p>그룹 선택</p>
          <SearchSelectorNotCreatable  placeholder="그룹을 선택하세요" />
        </div>
        <div className='cpo-charging-fee-search-modal-row divide'>
          <div>
            <p>무료 운영 충전소</p>
            <div><Toggle onOff={isFree} setOnOff={setIsFree} on={"활성화"} off={"비활성화"}/></div>
          </div>
          <div>
            <p>할인 중인 충전소</p>
            <div><Toggle onOff={isSale} setOnOff={setIsSale} on={"활성화"} off={"비활성화"}/></div>
          </div>
        </div>
        <div className='cpo-charging-fee-search-modal-button-container'>
          <div className='cpo-charging-fee-search-modal-button'>적용</div>
        </div>
      </div>
    </>
  )
}
export default ChargingFeeSearchModal;
import React, { useRef, useState, useEffect } from 'react';
import { Outlet, RouterProvider,createBrowserRouter, useNavigate } from 'react-router-dom'
import { getCookie, removeCookie, refresh, signedOut } from '../../util/util.js';

import '../../styles/Default.css';

export const MainTemplate = () => {
  let navigator = useNavigate();
  let refreshToken = getCookie("refreshToken");
  let signedToken = getCookie("signedToken");

  const [modalOn, setModalOn] = useState(false);
  const [modalIdx, setModalIdx] = useState(null);
  return (
    <div className='main-template-container' onClick={()=>{setModalOn(false)}}>
      <div className="main-template-header">
        <div className="main-template-header-inner">
          <div className="nav-logo-container">
            <img className='nav-logo' alt="" onClick={()=>{}}/>
          </div>
          <div className='nav-function-container'>
            {modalOn&&<>
              <div className='nav-menu-modal-container' onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setModalOn(true);}}>
                {
                  modalIdx == 0?
                  <>
                    <p className='nav-menu-modal-title'>PRODUCTS</p>
                    <div className='nav-products-menu-category'>
                      <p>공유형 CPO 서비스</p>
                      <p>Charger Station Management Subscriptions Service</p>
                    </div>
                    
                    <div className='nav-products-menu-category'>
                      <p>찾아ZooM: 충전소 최적입지 추천 서비스</p>
                      <p>AI based Simulation Service</p>
                    </div>
                    <div className='nav-products-menu-category'>
                      <p>OCPP 프록시 서비스</p>
                      <p>OCPP Proxy Service</p>
                    </div>

                  </>
                  :
                  <></>
                }
              </div>
            </>}
            <div className='nav-menu-container'>
              <div className='nav-menu-button' onClick={(e)=>{e.stopPropagation(); setModalOn(true); setModalIdx(0)}}>Products</div>
              <div className='nav-menu-button' onClick={(e)=>{e.stopPropagation(); setModalOn(true); setModalIdx(1)}}>Pricing</div>
              <div className='nav-menu-button' onClick={(e)=>{e.stopPropagation(); setModalOn(true); setModalIdx(2)}}>Support</div>
            </div>
            <div className='nav-sign-in-container'>
              {signedToken &&<div onClick={()=>{navigator("/cpo");}}><p>관리페이지</p></div>}
              {
                signedToken ?
                <div onClick={()=>{signedOut();}}><p>로그아웃</p></div>
                :<div onClick={()=>{navigator("/login");}}><p>로그인</p></div>
              }
              <div onClick={()=>{navigator("/sign-up");}}><p>회원가입</p></div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <Outlet/>
      </section>
      <footer>
        <p>Copyright (C) (주)모니트 All right Reserved.</p>
      </footer>
    </div>
  )
}
export default MainTemplate;
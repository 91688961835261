
import { ResponsiveLine } from '@nivo/line'

const LineChart = ({ data, axiosName, unit }) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 50, bottom: 80, left: 80 }}
        theme={{
          legends: {
            text: {
              fontSize : 18,
              fontFamily : 'NanumSquareNeoVariable',
            },
          },
          axis: {
            legend: {
              text: {
                fontFamily: 'Comic Sans',
              },
            },
          },
          crosshair: {
            line: {
                stroke: '#000',
                strokeWidth: 1,
                strokeOpacity: 0.35,
            },
          }
        }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: '0',
            max: 'auto',
            // stacked: true,
            reverse: false
        }}
        enableArea={true}
        yFormat={(val)=>{return val+=`${unit.y}`}}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -45,
            legend: '',
            legendOffset: 36,
            legendPosition: 'middle',
            truncateTickAt: 0
        }}

        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: `${unit.y}`,
            legendOffset: -50,
            legendPosition: 'middle',
            truncateTickAt: 0
        }}
        enablePoints={false}
        pointSize={15}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        enableTouchCrosshair={true}
        useMesh={true}
        colors={{
          datum: 'color'
        }}
        legends={[
            {
                anchor: 'top-right',
                direction: 'row',
                justify: false,
                translateX: 20,
                translateY: -30,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 14,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        crosshairType={'cross'}

        tooltip={(item)=>{
          if(!item)return;
          return(
          <div style={{position: 'relative', 'box-shadow': '0px 0px 8.34px 0px #00000040',background:"#ffffff",padding:15,borderRadius:8, }}>
            <div style={{display:"flex"}}>{axiosName.x} : <p style={{margin: 0, marginBottom:15, marginLeft:10, fontWeight:500}}>{item.point.data.xFormatted}</p></div>
            <div style={{display:"flex"}}>{axiosName.y} : <p style={{margin: 0, marginLeft:10, fontWeight:500}}>{item.point.data.yFormatted}</p></div>
            <div style={{position:'absolute', bottom:-20, left:'50%', transform:"translateX(-50%)",width:12,height:12,borderRadius:"100vh", background:"#fff",'box-shadow':`0px 0px 0px 3px ${item.point.borderColor}`  }}></div>
          </div>
          )
        }}
    />
)
export default LineChart;
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import { setCookie, getCookie } from '../../util/util';



export const getChargingTransactions = async(page, count, query)=>{
  const signedToken =  getCookie('signedToken')
  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/ChargingTransactions?Size=${count}&Page=${page}${query}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch((err)=>{
    console.log(err);
  })
  return temp;
}

export const getOccpMessageHistoriesList = async(page, query)=>{
  const signedToken =  getCookie('signedToken')

  let temp;
  await axios.get(`/controlhub/api/cpouser/v1/OcppMessageHistories?Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}
export const getOccpActionMessageHistoriesList = async(id)=>{
  const signedToken =  getCookie('signedToken')

  let temp = [];
  await axios.get(`/controlhub/api/cpouser/v1/OcppActionHistories?ocppTransactionId=${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}

export const getOccpMessageHistoriesByUniqueId = async(id)=>{
  const signedToken =  getCookie('signedToken')

  let temp = [];
  await axios.get(`/controlhub/api/cpouser/v1/OcppMessageHistories?UniqueId=${id}&SortOrder=Asc`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}


// chargerHub 정보 API GET
export const getChargerHubList = async(stationList)=>{
  const signedToken =  getCookie('signedToken')

  let temp = [];
  for await(const it of stationList){
    await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${it.ChargingStationId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
    .catch(()=>{})
  }
  return temp;
}


export const getTagAuthorization = async(page, query)=>{
  const signedToken =  getCookie('signedToken')
  let temp = [];
  console.log(query);

  await axios.get(`/controlhub/api/cpouser/v1/TagAuthorization?Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }).then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}

export const getChargerStatusHistories = async(page , query)=>{
  console.log(page)
  const signedToken =  getCookie('signedToken')
  let temp = [];

  await axios.get(`/controlhub/api/cpouser/v1/ChargerStatusHistories?Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }).then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}
export const getChargerErrorHistories = async(page , query)=>{
  const signedToken =  getCookie('signedToken')
  let temp = [];

  await axios.get(`/controlhub/api/cpouser/v1/ChargerStatusHistories?Status=Faulted&Size=15&Page=${page}${query?query:""}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  }).then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}
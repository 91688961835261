import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';
import { PiSlidersHorizontal } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";
import CpoChargerModelListItem from '../../components/list/CpoChargerModelListItem.js';
import CpoChargerModelEnrollModal from '../../components/modals/CpoChargerModelEnrollModal.js';

const CpoChargerModelManagement = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:5, idx:3}));
  },[])

  const signedToken =  getCookie('signedToken')

  
  const [searchKeyword, setSearchKeyword] = useState();

  const [chargerModelList, setChargerModelList] = useState();
  
  useEffect(()=>{
    getChargerModelList();
  },[])

  const getChargerModelList = async(id)=>{
    await axios.get(`/infra/api/ChargerModels`,{
    })
    .then((res)=>{
      let temp = [...res.data.Results];
      console.log(temp);
      setChargerModelList(temp);
    })
  }

  const [modalOpen, setModalOpen] = useState(false);

  const [deleteMode, setDeleteMode] = useState(false);

  const [checked, setChecked] = useState(null);

  return (
    <div className='cpo-charger-model-management-container'>
      {modalOpen&&<CpoChargerModelEnrollModal setOnOff={setModalOpen}/>}
      <div className="cpo-charger-model-management-inner">
        <div className={"cpo-charger-model-management-content-container "}>
          <div className="cpo-charger-model-management-content-header">
            <div className='cpo-charger-model-management-content-search-container'>
              <div className="cpo-charger-model-management-content-search-filter-button">검색필터<PiSlidersHorizontal/></div>
              <div className="cpo-charger-model-management-content-search-input-container">
                <input type="text" value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}}/>
                <FaSearch/>
              </div>
            </div>
            <div className='cpo-charger-model-management-content-function-button-container'>
              <div className='cpo-charger-model-management-content-function-button enroll' onClick={()=>{setModalOpen(true)}}>충전기 모델 등록</div>
              <div className='cpo-charger-model-management-content-function-button delete' onClick={()=>{setDeleteMode(!deleteMode)}}>삭제</div>
              {deleteMode && <div className='cpo-charger-model-management-content-function-button cancel' onClick={()=>{setDeleteMode(false)}}>취소</div>}
            </div>
          </div>
          <div className={'cpo-charger-model-management-item-list-grid-container '}>
            
              <div className="cpo-charger-model-management-content-item-list-container">
                {
                  !deleteMode ?
                  <div className="cpo-charger-model-management-content-item-column tab0">
                    <div>업체명</div>
                    <div>모델이름</div>
                    <div>종류</div>
                    <div>충전속도</div>
                    <div>출력</div>
                    <div>전압</div>
                    <div>전류</div>
                    <div>커넥터타입</div>
                    <div>등록년월</div>
                    <div></div>
                    <div></div>
                  </div>
                  :
                  <div className="cpo-charger-model-management-content-item-column tab1">
                    <div>
                      <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                        <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                        <span className="checkbox_icon"></span>
                      </label>
                    </div>
                    <div>업체명</div>
                    <div>모델이름</div>
                    <div>종류</div>
                    <div>충전속도</div>
                    <div>출력</div>
                    <div>전압</div>
                    <div>전류</div>
                    <div>커넥터타입</div>
                    <div>등록년월</div>
                    <div>중고여부</div>
                  </div>
                }
                

                {
                  chargerModelList && chargerModelList.map((it, idx)=>{
                    return(
                      <>
                        <CpoChargerModelListItem data={it} deleteMode={deleteMode}/>
                      </>
                    );
                  })
                }
                
              </div>
              
            </div>
          </div>
      </div>
    </div>
  )
}
export default CpoChargerModelManagement;

import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import {chargerTypeConverter, connectorTypeConverter} from "../../util/util.js"

import CpoPayReport from '../../pages/CPO/CpoPayReport.js';

const ChargerCardEventTab4 = (props) => {

  return (
    <>
      <CpoPayReport/>
    </>
  )
}
export default ChargerCardEventTab4;

import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'

import { CiWarning } from "react-icons/ci";


import {chargerTypeConverter, connectorTypeConverter} from "../../util/util.js"
import { deleteCharger } from '../../api/infra/infraApi.js';


const ChargerCardEventTab5 = (props) => {

  const reqDeleteCharger = async(id)=>{
    await deleteCharger(id);
    props.getChargerList();
    props.setOnOff(false);
  }

  return (
    <>
      <>
        <div className='cpo-payment-function-modal-content delete'>
          <div className='cpo-payment-function-modal-delete-alert'>
            <CiWarning/>
          </div>
          <div className='cpo-payment-function-modal-delete-msg'><p>충전기를 삭제하시겠습니까?</p></div>
          <div className='cpo-payment-function-modal-delete-info'><p>{props.data?.DisplaySerialNumber}</p></div>
          <div className='cpo-payment-function-modal-delete-button' onClick={(e)=>{e.stopPropagation();e.preventDefault(); reqDeleteCharger(props.data.ChargerId);}}><p>삭제</p></div>
        </div>
      </>
    </>
  )
}
export default ChargerCardEventTab5;

import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

export const ChasDetailModal = (props) => {
  
  return (
    <div className="cpo-chas-detail-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-chas-detail-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-chas-detail-modal-header">
          <title>상세 속성</title>
        </div>
        <div className='cpo-chas-detail-row'>
          <div className='cpo-chas-detail-column'>
            <p>invokeType</p>
          </div>
          <div className="cpo-chas-detail-content">
            <p>{props.data?.chControlHubAction.invokeType ? props.data?.chControlHubAction.invokeType:"-"}</p>
          </div>

          <div className='cpo-chas-detail-column'>
            <p>invokeKey</p>
          </div>
          <div className="cpo-chas-detail-content">
            <p>{props.data?.chControlHubAction.invokeKey ? props.data?.chControlHubAction.invokeKey:"-"}</p>
          </div>

          <div className='cpo-chas-detail-column'>
            <p>className</p>
          </div>
          <div className="cpo-chas-detail-content">
            <p>{props.data?.chControlHubAction.className ? props.data?.chControlHubAction.className:"-"}</p>
          </div>
        </div>
          


      </div>
    </div>
  )
}
export default ChasDetailModal

import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, Routes, Route, Link, Switch, useParams, useLocation } from "react-router-dom";

import {setNum} from '../../store.js'
import axios from 'axios';
import { setCookie, getCookie , timestampFormat} from '../../util/util';
import { PiSlidersHorizontal } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

import CpoLogSearchModal from '../../components/modals/CpoLogSearchModal.js';
import CpoLogDetailModal from '../../components/modals/CpoLogDetailModal.js';
import { getChargerStatusHistories, getOccpMessageHistoriesList, getTagAuthorization, getChargerErrorHistories } from '../../api/controlhub/controlhubApi.js';
import CpoLogReportErrorItem from '../../components/list/CpoLogReportErrorItem.js';
const CpoLogReport = (props) => {
  const params = useParams();
  const location = useLocation();
  const navigator = useNavigate();
  
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  useEffect(()=>{
    if(location.pathname.replace('/cpo/log-report','').length !== 0)dispatch(setNum({id:3, idx:0, detail:true}));
    else{
      setTabIdx(0);
      setEndPoint("authorize");
      dispatch(setNum({id:3, idx:0}));
    }
  },[])

  const [endPoint, setEndPoint] = useState();

  useEffect(()=>{
    let val = location.pathname.replace('/cpo/log-report','');
    if(val.replace('/cpo/log-report','').length !== 0){
      let temp =val.split('/');
      switch (temp[1]) {
        case 'authorize':
          setTabIdx(0);
          break;
        case 'message':
          setTabIdx(1);
          break;
        case 'status':
          setTabIdx(2);
          break;
        case 'error':
          setTabIdx(3);
          break;
      
        default:
          break;
      }
      setEndPoint(temp[1]);
    }
    else{
      // window.location.replace('/cpo/log-report/authorize/1');
    }
  })



  const [tabIdx, setTabIdx] = useState(null)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }
  
  const [searchKeyword, setSearchKeyword] = useState();

  useEffect(()=>{
    if(tabIdx == 0){
      reqTagAuthorization(params.id,query);
    }
    if(tabIdx == 1){
      reqOccpMessageHistoriesList(params.id,query);
    }
    if(tabIdx == 2){
      reqChargerStatusHistories(params.id,query);
    }
    if(tabIdx == 3){
      reqChargerErrorHistories(params.id,query);
    }
  },[params.id])
  
  useEffect(()=>{
    if(tabIdx == 0){
      reqTagAuthorization(params.id,query);
    }
    if(tabIdx == 1){
      reqOccpMessageHistoriesList(params.id,query);
    }
    if(tabIdx == 2){
      reqChargerStatusHistories(params.id,query);
    }
    if(tabIdx == 3){
      reqChargerErrorHistories(params.id,query);
    }
    // state 초기화
    resetState();
    setQuery("");
  },[tabIdx])


  const [dataListTab0, setDateListTab0] = useState([]);
  const [dataListTab1, setDateListTab1] = useState([]);
  const [dataListTab2, setDateListTab2] = useState([]);
  const [dataListTab3, setDateListTab3] = useState([]);

  // API 요청 트리거
  let reqOccpMessageHistoriesList = async(page,query)=>{
    let val = await getOccpMessageHistoriesList(page?page:1,query);
    setDateListTab1([...val.results]);
    setPageCount(Math.ceil(val.total/showContentCount));
  }
  let reqTagAuthorization = async(page,query)=>{
    let val = await getTagAuthorization(page?page:1,query)
    setDateListTab0([...val.results]);
    setPageCount(Math.ceil(val.total/showContentCount));
  }
  let reqChargerStatusHistories = async(page,query)=>{
    let val = await getChargerStatusHistories(page?page:1,query)
    setDateListTab2([...val.results]);
    setPageCount(Math.ceil(val.total/showContentCount));
  }
  let reqChargerErrorHistories = async(page,query)=>{
    let val = await getChargerErrorHistories(page?page:1,query)
    setDateListTab3([...val.results]);
    setPageCount(Math.ceil(val.total/showContentCount));
  }

  const [query, setQuery] = useState("");
  useEffect(()=>{
    if(tabIdx == 0){
      reqTagAuthorization(1, query?query:"");
    }
    if(tabIdx == 1){
      reqOccpMessageHistoriesList(1, query?query:"");
    }
    if(tabIdx == 2){
      reqChargerStatusHistories(1, query?query:"");
    }
    if(tabIdx == 3){
      reqChargerErrorHistories(1, query?query:"");
    }
  },[query])

  // 쿼리 생성 함수
  let makeQueryTagAuthorization = (DisplaySerialNumber,TagId,MinCreatedDate,MaxCreatedDate, clickedResultList, resultListData)=>{
    let temp = ""
    if(DisplaySerialNumber) temp += `&ChargerDisplaySerialNumber=${DisplaySerialNumber}`;
    if(TagId)temp += `&IdTag=${TagId}`;
    if(MinCreatedDate) temp += `&MinAuthorizationDateTime=${MinCreatedDate}`
    if(MaxCreatedDate) temp += `&MaxAuthorizationDateTime=${MaxCreatedDate}`
    if(resultListData) {temp += `&AuthorizationResults=${resultListData}`}
    // AuthorizationResult
    setQuery(temp);
  }
  let makeQueryOccpMessage = (DisplaySerialNumber,TagId,MinCreatedDate,MaxCreatedDate, clickedActionList, clickedMessageTypeList, actionListData, messageTypeListData)=>{
    let temp = ""
    if(DisplaySerialNumber) temp += `&ChargerDisplaySerialNumber=${DisplaySerialNumber}`;
    if(TagId)temp += `&OcppTransactionId=${TagId}`;
    if(MinCreatedDate) temp += `&MinTimestamp=${MinCreatedDate}`
    if(MaxCreatedDate) temp += `&MaxTimestamp=${MaxCreatedDate}`
    if(actionListData) temp += `&Actions=${actionListData}`
    if(messageTypeListData) temp += `&MessageTypes=${messageTypeListData}`
    // Action MessageType
    
    setQuery(temp);
  }
  
  let makeQueryChargerStatusHistories = (DisplaySerialNumber,connectorAmount,MinCreatedDate,MaxCreatedDate,clickedChargerStatusList, chargerStatusData)=>{
    let temp = ""
    if(DisplaySerialNumber) temp += `&ChargerDisplaySerialNumber=${DisplaySerialNumber}`;
    if(connectorAmount)temp += `&ConnectorId=${connectorAmount}`;
    if(MinCreatedDate) temp += `&MinTimestamp=${MinCreatedDate}`
    if(MaxCreatedDate) temp += `&MaxTimestamp=${MaxCreatedDate}`
    if(chargerStatusData) temp += `&Statuses=${chargerStatusData}`
    // Status
    setQuery(temp);
  }
  let makeQueryChargerErrorHistories = (DisplaySerialNumber,connectorAmount,MinCreatedDate,MaxCreatedDate,VendorErrorCode,VendorId, clickedErrorCodeList, errorCodeData)=>{
    
    let temp = ""
    if(DisplaySerialNumber) temp += `&ChargerDisplaySerialNumber=${DisplaySerialNumber}`;
    if(connectorAmount)temp += `&ConnectorId=${connectorAmount}`;
    if(MinCreatedDate) temp += `&MinTimestamp=${MinCreatedDate}`
    if(MaxCreatedDate) temp += `&MaxTimestamp=${MaxCreatedDate}`
    if(VendorErrorCode) temp += `&VendorErrorCode=${VendorErrorCode}`
    if(VendorId) temp += `&VenderId=${VendorId}`
    if(errorCodeData) temp += `&ErrorCodes=${errorCodeData}`
    // ErrorCode
    setQuery(temp);
  }

  const [searchModalOn, setSearchModalOn] = useState(false);
  const [detailModalOn, setDetailModalOn] = useState(false);

  const [detailData, setDetailData] = useState();



  // 총 페이지 갯수
  const [pageCount, setPageCount] = useState(null);

  // 한 번에 보일 컨텐츠 수
  let showContentCount = 15;

  // 현재 보일 첫 페이지 번호 반환 함수
  const getInitPage = (pageNum)=>{
    return (Math.ceil(pageNum/5)-1)*5+1;
  }
  // 현재 보일 마지막 페이지 번호 반환 함수
  const getEndPage = (pageNum)=>{
    return getInitPage(pageNum)+5;
  }
  // 페이지 이동함수
  const movePage = (page)=>{
    navigator(`/cpo/log-report/${endPoint}/${page}`, {state: {detail:true}});
  }
  // 페이지 선택자 렌더링 함수
  const pageSelectorRendering = ()=>{
    let page = params.id?params.id:1;
    let initPage = getInitPage(params.id?params.id:1);
    let endPage = initPage + 5;

    const result = [];
    // 화면에 최대 5개의 페이지 버튼 생성
    for (let id = initPage; id < endPage && id <= pageCount; id++) {
      result.push(<div className={'cpo-pay-report-page-selector ' + `${id == page ? "clicked": !page&&id==1 ?"clicked":""}`} onClick={()=>{movePage(id)}}>{id}</div>)
    }
    return result;
  }



  // 공통
  // 식별번호
  const [DisplaySerialNumber, setDisplaySerialNumber] = useState();
  // idTag
  const [TagId, setTagId] = useState();
  // 생성일
  const [MinCreatedDate, setMinCreatedDate] = useState();
  const [MaxCreatedDate, setMaxCreatedDate] = useState();
  // 커넥터 개수
  const [connectorAmount, setConnectorAmount] = useState(null);

  // tab0
  const [clickedResultList, setClickedResultList] = useState([]);
  // tab1
  const [clickedActionList, setClickedActionList] = useState([]);
  const [clickedMessageTypeList, setClickedMessageTypeList] = useState([]);


  // tab2
  const [clickedChargerStatusList, setClickedChargerStatusList] = useState([]);

  // tab3
  const [clickedErrorCodeList, setClickedErrorCodeList] = useState([]);
  const [VendorId, setVendorId] = useState();
  const [VendorErrorCode, setVendorErrorCode] = useState();

  const resetState = ()=>{
    setDisplaySerialNumber();
    setTagId();
    setMinCreatedDate();
    setMaxCreatedDate();
    setConnectorAmount();
    setClickedResultList([]);
    setClickedActionList([]);
    setClickedMessageTypeList([]);
    setClickedChargerStatusList([]);
    setClickedErrorCodeList([]);
    setVendorId();
    setVendorErrorCode();
  }



  return (
    <div className='cpo-log-report-container'>
      {searchModalOn && <CpoLogSearchModal setOnOff={setSearchModalOn} tabIdx={tabIdx} DisplaySerialNumber={DisplaySerialNumber} setDisplaySerialNumber={setDisplaySerialNumber} TagId={TagId} setTagId={setTagId} connectorAmount={connectorAmount} setConnectorAmount={setConnectorAmount} MinCreatedDate={MinCreatedDate} setMinCreatedDate={setMinCreatedDate} MaxCreatedDate={MaxCreatedDate} setMaxCreatedDate={setMaxCreatedDate}clickedActionList={clickedActionList}setClickedActionList={setClickedActionList}clickedMessageTypeList={clickedMessageTypeList}setClickedMessageTypeList={setClickedMessageTypeList}clickedChargerStatusList={clickedChargerStatusList}setClickedChargerStatusList={setClickedChargerStatusList}clickedErrorCodeList={clickedErrorCodeList}setClickedErrorCodeList={setClickedErrorCodeList}
      clickedResultList={clickedResultList}
      setClickedResultList={setClickedResultList}
      makeQueryOccpMessage={makeQueryOccpMessage}
      makeQueryTagAuthorization={makeQueryTagAuthorization}
      makeQueryChargerStatusHistories={makeQueryChargerStatusHistories}
      makeQueryChargerErrorHistories={makeQueryChargerErrorHistories}
      VendorId={VendorId}
      setVendorId={setVendorId}
      VendorErrorCode={VendorErrorCode}
      setVendorErrorCode={setVendorErrorCode}
      />}
      {detailModalOn && <CpoLogDetailModal data={detailData} setOnOff={setDetailModalOn}/>}
      <div className="cpo-log-report-inner">
        <div className='cpo-log-report-tab-container'>
          <div className={tabIdx == 0 ? "log-report-tab-button selected":"log-report-tab-button"} onClick={()=>{navigator(`/cpo/log-report/authorize/1`, {state: {detail:true}});}}><p>충전기 회원 인증 이력</p></div>
          <div className={tabIdx == 1 ? "log-report-tab-button selected":"log-report-tab-button"} onClick={()=>{navigator(`/cpo/log-report/message/1`, {state: {detail:true}});}}><p>충전기 통신 이력</p></div>
          <div className={tabIdx == 2 ? "log-report-tab-button selected":"log-report-tab-button"} onClick={()=>{navigator(`/cpo/log-report/status/1`, {state: {detail:true}});}}><p>충전기 상태 변경 이력</p></div>
          <div className={tabIdx == 3 ? "log-report-tab-button selected":"log-report-tab-button"} onClick={()=>{navigator(`/cpo/log-report/error/1`, {state: {detail:true}});}}><p>충전기 오류 이력</p></div>
          <div className={'log-report-tab-bar '+'tab'+tabIdx}></div>
        </div>
        <div className="cpo-log-report-content-container">
          <div className="cpo-log-report-content-search-container">
            <div className="cpo-log-report-content-search-filter-button" onClick={()=>{setSearchModalOn(true);}}>검색필터<PiSlidersHorizontal/></div>
            <div className="cpo-log-report-content-search-input-container">
              <input type="text" value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}}/>
              <FaSearch/>
            </div>
          </div>
          <div className="cpo-log-report-content-item-list-container">
            {
              tabIdx == 0?
              <div className="cpo-log-report-content-item-column log-tab0">
                <div>충전기식별번호</div>
                <div>회원 idTag</div>
                <div>인증 결과</div>
                <div>인증 날짜</div>
              </div>
            : tabIdx == 1?
              <div className="cpo-log-report-content-item-column log-tab1">
                <div>충전기식별번호</div>
                <div>트랜잭션 ID</div>
                <div>통신 날짜</div>
                <div>액션명</div>
                <div>메시지 유형</div>
                <div>상세정보</div>

              </div>
            : tabIdx == 2?
              <div className="cpo-log-report-content-item-column log-tab2">
                <div>충전기식별번호</div>
                <div>커넥터 번호</div>
                <div>날짜</div>
                <div>상태</div>
              </div>
            :
              <div className="cpo-log-report-content-item-column log-tab3">
                <div>충전기식별번호</div>
                <div>커넥터 번호</div>
                <div>날짜</div>
                <div>에러코드</div>
                <div>에러 정보</div>
                <div>벤더 정보</div>
              </div>
            }

            {
              tabIdx == 0 && dataListTab0.map((it, idx)=>{
                return(
                  <>
                    <div className='cpo-log-report-content-item log-tab0'>
                      <div><p>{it.charger.displaySerialNumber}</p></div>
                      <div><p>{it.tag.idTag}</p></div>
                      <div>
                        <div className={'cpo-log-report-content-item-authorization ' + `${it.authorizationResult}`}><p>{it.authorizationResult}</p></div>
                      </div>
                      <div><p>{timestampFormat(new Date(it.createdDateTime))}</p></div>
                    </div>
                  </>
                );
              })
            }
            {
              tabIdx == 1 && dataListTab1.map((it, idx)=>{
                return(
                  <>
                    <div className='cpo-log-report-content-item log-tab1'>
                      <div><p>{it.charger.displaySerialNumber}</p></div>
                      <div><p>{it.ocppTransactionId}</p></div>
                      <div><p>{timestampFormat(new Date(it.timestamp))}</p></div>
                      <div><p>{it.action}</p></div>
                      <div>
                        <div className={"cpo-log-report-messageType "+`${it.messageType == "CALL"?"request": it.messageType == "CALLRESULT"? "confirm":it.messageType == "CALLERROR"?"":"error"}`}><p>{it.messageType == "CALL"? "REQUEST" :it.messageType == "CALLRESULT"? "CONFIRM" :it.messageType == "CALLERROR"? "ERROR" :"-"}</p></div>
                      </div>
                      <div><p className='cpo-log-report-detail-button' onClick={()=>{setDetailModalOn(true); setDetailData(dataListTab1[idx])}}>자세히보기</p></div>
                    </div>
                  </>
                );
              })
            }
            {
              tabIdx == 2 && dataListTab2.map((it, idx)=>{
                return(
                  <>
                    <div className='cpo-log-report-content-item log-tab2'>
                      <div><p>{it.charger.displaySerialNumber}</p></div>
                      <div><p>{it.connectorId}</p></div>
                      <div><p>{timestampFormat(new Date(it.timestamp))}</p></div>
                      <div><p>{it.status}</p></div>
                    </div>
                  </>
                );
              })
            }
            {
              tabIdx == 3 && dataListTab3.map((it, idx)=>{
                return(
                  <>
                    <CpoLogReportErrorItem data={it}/>
                  </>
                );
              })
            }
            
          </div>
          {
            <div className='cpo-pay-report-page-selector-container'>
              {(params.id>5) &&<FaChevronLeft onClick={()=>{movePage(getInitPage(params.id?params.id:1) -1)}}/>}
              {pageSelectorRendering()}
              {(getEndPage(params.id?params.id:1) < pageCount) &&<FaChevronRight onClick={()=>{movePage(getEndPage(params.id?params.id:1))}}/>}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default CpoLogReport;
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import { PiSlidersHorizontal } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";


import { setCookie, getCookie } from '../../util/util';

import ChargingFeeModalChargerItem from '../list/ChargingFeeModalStationItem';

import ChargingFeeSearchModal from './ChargingFeeSearchModal';

export const ChargingFeeSettingModal = (props) => {

  let signedToken = getCookie("signedToken");

  const [searchKeyword, setSearchKeyword] = useState();

  const [alertOn, setAlertOn] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(()=>{
    if(alertOn){
      setTimeout(() => {
        setAlertOn(false)
      }, 1300);
    }
  },[alertOn])

  const [searchModalOn, setSearchModalOn] = useState(false);

  return (
    <div className="cpo-charging-fee-setting-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-charging-fee-setting-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setSearchModalOn(false)}}>
        {alertOn && <div className='cpo-charging-fee-setting-modal-alert'><p>정상적으로 적용이 완료되었습니다.</p></div>}
        <div className="cpo-charging-fee-setting-modal-header">
          <title onClick={()=>{console.log(props.chargerRateList);}}>이벤트 기간 설정</title>
          <p>이벤트를 추가할 충전소를 선택 후, 기간을 입력하세요</p>
        </div>
        <div className="cpo-charging-fee-setting-modal-search-container">
          {searchModalOn && <ChargingFeeSearchModal/>}
          <div className="cpo-charging-fee-setting-modal-search-filter-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault();setSearchModalOn(!searchModalOn)}}>검색필터<PiSlidersHorizontal/></div>
          <div className="cpo-charging-fee-setting-modal-search-input-container">
            <input type="text" placeholder={'충전소 이름으로 검색하기'} value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}}/>
            <FaSearch/>
          </div>
        </div>
        <div className='cpo-charging-fee-setting-modal-table'>
          <div className='cpo-charging-fee-setting-modal-table-column'>
            <div className='cpo-charging-fee-setting-modal-table-column-item'>
              <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
                <span className="checkbox_icon"></span>
              </label>
              <p>전체</p>
            </div>
            <div className='cpo-charging-fee-setting-modal-table-column-item'><p>충전소명</p></div>
            <div className='cpo-charging-fee-setting-modal-table-column-item'><p>요금적용상태</p></div>
            <div className='cpo-charging-fee-setting-modal-table-column-item'><p>무료 운영기간</p></div>
            <div className='cpo-charging-fee-setting-modal-table-column-item'><p>할인기간</p></div>
          </div>
          <div className='cpo-charging-fee-setting-modal-item-list'>
            {
            props.chargerInfo&&
              (props.stationInfo?.map((ia,idx)=>{
                return(
                  <ChargingFeeModalChargerItem setAlertOn={setAlertOn} data={ia} />
              )
              }))
            }
          </div>
        </div>
        <div className='cpo-charging-fee-setting-modal-period-selection-container'>
          <div>
            <p>무료운영기간</p>
            <div className='cpo-charging-fee-setting-modal-period-input-wrapper'>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date'  max={"9999-12-31"} placeholder='' onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div>~</div>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date'  max={"9999-12-31"} placeholder='' onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div className='cpo-charging-fee-setting-modal-period-button'>
                적용
              </div>
            </div>
          </div>
          <div>
            <p>할인기간</p>
            <div className='cpo-charging-fee-setting-modal-period-input-wrapper'>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date'  max={"9999-12-31"} placeholder='' onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div>~</div>
              <div className='cpo-charging-fee-setting-modal-period-input-container'>
                <input type='date'  max={"9999-12-31"} placeholder='' onSubmit={()=>{return false}} onClick={(e)=>{e.stopPropagation(); }}/>
              </div>
              <div className='cpo-charging-fee-setting-modal-period-button'>
                적용
              </div>
            </div>
          </div>
        </div>
        <div className='cpo-charging-fee-setting-modal-save-button-container'>
          <div className='cpo-charging-fee-setting-modal-save-button'>저장하기</div>
        </div>
      </div>
    </div>
  )
}
export default ChargingFeeSettingModal;


import React,{useState, useEffect, useRef} from 'react'
import { BsEvStation } from "react-icons/bs";
import { TbMapPinBolt } from "react-icons/tb";

const Carousel = (props) => {
  useEffect(()=>{
    stationBlockRef.current[props.selected]?.scrollIntoView({behavior:"smooth"});
    document.getElementsByClassName('main-page-container')[0].scrollTo({top:0, left:0,behavior:"smooth"});
  },[props.selected])
  let stationBlockRef = useRef([]);
  return (
    <div className="carousel-container">
      {props.items?.map((it, idx)=>{
        return( 
          <>
            <div key={'station-'+idx} ref={(el) => (stationBlockRef.current[idx] = el)}  onClick={()=>{!(props.selected==idx) ? props.setSelected(idx) : props.setSelected(null) }} className={props.selected === idx ?'carousel-item selected':'carousel-item'}>
              <div className="station-item-header">
                <div className='station-icon-container'>
                  <BsEvStation className='station-icon'/>
                </div>
                <div className='station-name'>{it.ChargingStationName}</div>
                <div className='station-id'>
                  {/* 충전기 일련번호로 변경예정 */}
                  {it.DisplayId}
                </div>
              </div>
              <div className='station-item-line'></div>
              <div className="station-address-title-container">
                <TbMapPinBolt className='station-pin-icon'/>
                <p>주소</p>
              </div>
              <div className='station-address-container'>
                <p>{it.Address == null ? "-" :`${it.Address} ${it.DetailedAddress?it.DetailedAddress:""}`}</p>
              </div>
            </div>
          </>
        )
      })}
    </div>
  )
}
export default Carousel;
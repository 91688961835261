import axios from 'axios';
import React, { useRef, useState, useEffect } from 'react';
import { FaCheck } from "react-icons/fa6";
import { getCookie } from '../../util/util.js';

let a={
  "currentBillingType": "MONTHLY",
  "lastBillingDate": "2024-08-06T04:31:08.959633",
  "lastBillingOrderId": "d75b1708-cf75-444d-ae6c-0d52ed8f0c32",
  "paymentCardId": "941ddb05-cc6a-47c6-ae85-3cc797730f84",
  "renewalStatus": true,
  "subscriptionId": "02e5a7fb-c7d3-4f9a-ba54-f7e74d7c0904",
  "subscriptionProduct": {
      "subscriptionProductId": "0d1bac71-47f8-43d4-907e-22e0373228aa",
      "name": "pro",
      "normalizedName": "PRO"
  },
  "endDate": "2024-09-06T00:00:00",
  "startDate": "2024-08-06T00:00:00",
  "cpo": {
      "cpoId": "c87afec3-a3fc-4abe-88c9-d6eacf23a901",
      "cpoName": "이남경",
      "serialNumber": "AC"
  }
}
export const SubscriptionEnrolledCard = (props) => {
  let signedToken = getCookie("signedToken");  

  function formatDate(isoString) {
    
    // ISO 8601 문자열을 Date 객체로 변환
    const date = new Date(isoString);

    // 연도, 월, 일을 추출
    const year = String(date.getFullYear()).slice(2); // 연도의 마지막 두 자리를 가져옴
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월을 2자리로 맞춤
    const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 맞춤

    // YY-MM-DD 형식으로 변환하여 반환
    return `${year}.${month}.${day}`;
  }

  const [detailedModalOn, setDetailedModalOn] = useState(false);
  useEffect(()=>{
    console.log(props.detailedInfo)
  },[])


  const removeSubscriptionCancel = async()=>{
    await axios.put(`/subscription/api/cpoadmin/Subscriptions/${props.data?.subscriptionId}/Cancel`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    }).then((res)=>{
      console.log(res);
      props.getCurrentSubscriptionList();
    })
  }


  return (
    <div className={"cpo-subscription-enrolled-card-container "}>
      
      <div className="cpo-subscription-enrolled-card-title-container">
        {
        detailedModalOn&&<EnrolledSubscriptionDetailedModal detailedInfo={props.detailedInfo}/>
        }
        <p className='cpo-subscription-title'>{props.data.subscriptionProduct.normalizedName}</p>
        <p onClick={()=>{setDetailedModalOn(!detailedModalOn)}}>혜택 자세히보기</p>
      </div>
      <div className='cpo-subscription-enrolled-card-divider'></div>
      <div className='cpo-subscription-enrolled-card-status-container'>
        <p>{props.data.currentBillingType =="MONTHLY" ? "월간 ":"연간 "}</p>
        <p>{props.data.renewalStatus ? "구독중":"구독 만료 예정"}</p>
      </div>
      <div className='cpo-subscription-enrolled-card-divider'></div>
      <div className="cpo-subscription-enroll-date-container">
        <div>
          <p className={'cpo-subscription-enroll-date-title ' + `${props.data.renewalStatus ?"":"cancelStart"}`}>시작일</p>
          <p className={"cpo-subscription-enroll-date " + `${props.data.renewalStatus ?"":"cancelStart"}` }>{formatDate(props.data.startDate)}</p>
        </div>
        <p>/</p>
        <div>
          <p className='cpo-subscription-enroll-date-title'>{props.data.renewalStatus ? "결제 예정일" : "만료 예정일"}</p>
          <p className={"cpo-subscription-enroll-date " + `${props.data.renewalStatus ?"":"cancel"}`}>{formatDate(props.data.endDate)}</p>
        </div>
        {props.data.renewalStatus ?
          <div className='cpo-subscription-termination-btn' onClick={(e)=>{e.stopPropagation();}}>
            <p>
              해지
            </p>
          </div>
          :
          <div className='cpo-subscription-termination-btn cancel' onClick={(e)=>{e.stopPropagation(); }}>
            <p>
              해지 취소
            </p>
          </div>
        }
      </div>
    </div>
  )
}
export default SubscriptionEnrolledCard;

const EnrolledSubscriptionDetailedModal = (props)=>{
  
  return(
    <>
      <div className='cpo-subscription-detailed-modal-container'>
        <div className='cpo-subscription-detailed-modal-content-wrapper'>
          <FaCheck/>
          <p>{props.detailedInfo.emoovFeatures.chargerLimit}개 충전기</p>
        </div>
      </div>
    </>
  );
}
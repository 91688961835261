import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import Selector2 from '../buttons/Selector2';
import { getCookie } from '../../util/util';

export const ChasAppendModal = (props) => {
  let signedToken = getCookie("signedToken");
  const [chasName, setChasName] = useState();
  const [chasDesc, setChasDesc] = useState();
  const [chasValid, setChasValid] = useState(null);


  const nameVaildation = async()=>{
    if(!chasName)return;
    axios.get(`/controlhub/api/cpouser/v1/ActionSets/Validation-Name?name=${chasName}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data)
      setChasValid(res.data);
    })
  }
  const appendChas = async()=>{
    axios.post(`/controlhub/api/cpouser/v1/ActionSets`,{
      "name": chasName,
      "description": chasDesc
    },{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data)
      props.getCHASList();
      props.setOnOff(false);
    })
  }

  return (
    <div className="cpo-chas-duplication-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-chas-duplication-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-chas-duplication-modal-header">
          <title>CHAS 추가</title>
        </div>
        <div className='cpo-chas-duplication-modal-content-container'>
          <p>CHAS 이름</p>
          <div className='cpo-chas-duplication-modal-grid-wrapper'>
            <div className='cpo-chas-duplication-modal-input-container'>
              <input type="text" placeholder='CHAS의 이름을 입력해주세요' value={chasName} onChange={(e)=>{setChasName(e.target.value);setChasValid(null)}}/>
            </div>
            <div className='cpo-chas-duplication-check-button ' onClick={()=>{nameVaildation()}}>중복확인</div>
            {(chasName =="" || chasValid === null) &&<p className='cpo-chas-duplication-check-info'>중복확인을 눌러 주세요.</p>}
            {chasValid === false &&<p className='cpo-chas-duplication-check-info'>중복된 이름입니다.</p>}
          </div>
        </div>
        <div className='cpo-chas-duplication-modal-content-container'>
          <p>설명</p>
          <div className='cpo-chas-duplication-modal-input-container textarea'>
            <textarea type="text" placeholder='설명을 입력해주세요' value={chasDesc} onChange={(e)=>{setChasDesc(e.target.value);}} />
          </div>
        </div>
        
        <div className={`cpo-chas-duplication-modal-enroll-button append `+ `${chasValid && !(chasDesc==undefined || chasDesc ==null || chasDesc == "") ? "enable":""}`} onClick={()=>{appendChas()}}>추가하기</div>

      </div>
    </div>
  )
}
export default ChasAppendModal

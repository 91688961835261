import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { setCookie, getCookie } from '../../util/util';
import { CiWarning } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";
import Selector2 from '../buttons/Selector2';

export const CpoChargerModelEnrollModal = (props) => {
  const signedToken =  getCookie('signedToken')

  
  const [companyCategory, setCompanyCategory] = useState(null);
  const [name, setName] = useState(null);
  const [chargerType, setChargerType] = useState(null);
  const [ChargingSpeed, setChargingSpeed] = useState(null);
  const [ChargingPower, setChargingPower] = useState(null);
  const [Voltage, setVoltage] = useState(null);
  const [ElectricCurrent, setElectricCurrent] = useState(null);
  
  const [ActualRegistrationDateYear, setActualRegistrationDateYear] = useState(null);
  const [ActualRegistrationDateMonth, setActualRegistrationDateMonth] = useState(null);
  
  const [Remarks, setRemarks] = useState(null);
  const [DetailedSpec, setDetailedSpec] = useState(null);
  

  const [companyCategoryActive, setCompanyCategoryActive] = useState();
  const [ActualRegistrationDateYearActive, setActualRegistrationDateYearActive] = useState();
  const [ActualRegistrationDateMonthActive, setActualRegistrationDateMonthActive] = useState();
  const [chargerTypeActive, setChargerTypeActive] = useState();

  const currentYear = new Date().getFullYear();
  const yearList = Array.from({ length: 30 }, (_, i) => ({
    name: String(currentYear + i)
  }));
  const monthList = Array.from({ length: 12 }, (_, i) => ({
    name: String(1 + i)+'월'
  }));

  const activeReset = ()=>{
    setCompanyCategoryActive(false);
    setChargerTypeActive(false);
    setActualRegistrationDateYearActive(false);
    setActualRegistrationDateMonthActive(false);
  }

  let companyCategoryList=[
    {name:"충전기설치"},
    {name:"캐노피설치"},
    {name:"윈치설치"},
    {name:"충전기제조사"}
  ];
  let chargerTypeList=[
    {name:"고속"},
    {name:"중속"},
    {name:"저속"},
  ];

  const [checked0, setChecked0] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);



  const [IsUsed, setIsUsed] = useState(null);

  return (
    <div className="cpo-charger-model-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-charger-model-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-model-enroll-modal-header">
          <title>모델 신규등록</title>
          <div className='cpo-charger-model-enroll-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="cpo-charger-model-enroll-modal-content">
          <div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper'>
              <p>충전기 제조사 업체 선택</p>
              <div className="cpo-charger-model-enroll-modal-content-selector-container">
                <Selector2 name={'name'} dataList={companyCategoryList} select={companyCategory} setSelect={setCompanyCategory} active={companyCategoryActive} setActive={setCompanyCategoryActive} activeReset={activeReset}/>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p>모델이름</p>
              <div className="cpo-charger-model-enroll-modal-content-input-container">
                <input type="text" placeholder='모델명을 입력해주세요' value={name} onChange={(e)=>{setName(e.target.value)}} />
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper triple marginTop'>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p>출력</p>
                <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                  <input type="text" placeholder='0' value={ChargingPower} onChange={(e)=>{setChargingPower(e.target.value)}} />
                  <p>kWh</p>
                </div>
              </div>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p>전압</p>
                <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                  <input type="text" placeholder='0' value={Voltage} onChange={(e)=>{setVoltage(e.target.value)}} />
                  <p>V</p>
                </div>
              </div>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p>전류</p>
                <div className="cpo-charger-model-enroll-modal-content-input-container postFix">
                  <input type="text" placeholder='0' value={ElectricCurrent} onChange={(e)=>{setElectricCurrent(e.target.value)}} />
                  <p>A</p>
                </div>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p>등록년월</p>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper triple'>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <div className="cpo-charger-model-enroll-modal-content-selector-container">
                  <Selector2 maxCount={5} name={'name'} dataList={yearList} select={ActualRegistrationDateYear} setSelect={setActualRegistrationDateYear} active={ActualRegistrationDateYearActive} setActive={setActualRegistrationDateYearActive} activeReset={activeReset}/>
                </div>
              </div>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <div className="cpo-charger-model-enroll-modal-content-selector-container">
                  <Selector2 maxCount={5} name={'name'} dataList={monthList} select={ActualRegistrationDateMonth} setSelect={setActualRegistrationDateMonth} active={ActualRegistrationDateMonthActive} setActive={setActualRegistrationDateMonthActive} activeReset={activeReset}/>
                </div>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p>설명</p>
              <div className="cpo-charger-model-enroll-modal-content-textarea-container">
                <textarea type="text" placeholder='설명을 입력해주세요' value={Remarks} onChange={(e)=>{setRemarks(e.target.value)}} />
              </div>
            </div>
          </div>
          <div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper'>
              <p>충전속도</p>
              <div className="cpo-charger-model-enroll-modal-content-input-container">
                <input type="text" placeholder='0' value={ChargingSpeed} onChange={(e)=>{setChargingSpeed(e.target.value)}} />
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p>충전기 종류</p>
              <div className="cpo-charger-model-enroll-modal-content-selector-container">
                <Selector2 name={'name'} dataList={chargerTypeList} select={chargerType} setSelect={setChargerType} active={chargerTypeActive} setActive={setChargerTypeActive} activeReset={activeReset}/>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper divide marginTop'>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p>커넥터 타입</p>
                <div className="cpo-charger-model-enroll-modal-checkbox-container">
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input type="checkbox" checked={checked0} onChange={()=>{setChecked0(!checked0)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>DC차데모</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input type="checkbox" checked={checked1} onChange={()=>{setChecked1(!checked1)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>AC3상</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input type="checkbox" checked={checked2} onChange={()=>{setChecked2(!checked2)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>DC콤보</p>
                  </div>
                  <div>
                    <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
                      <input type="checkbox" checked={checked3} onChange={()=>{setChecked3(!checked3)}} />
                      <span className="checkbox_icon"></span>
                    </label>
                    <p>AC완속</p>
                  </div>
                </div>
              </div>
              <div className='cpo-charger-model-enroll-modal-content-wrapper'>
                <p>중고여부</p>
                <div className="cpo-charger-model-enroll-modal-radio-container">
                  <div>
                    <div className='radio-button-container'>
                      <div className={'radio-button '+`${IsUsed == 0 ? "clicked":""}`} onClick={()=>{setIsUsed(0)}}></div>
                      <p>중고</p>
                    </div>
                  </div>
                  <div>
                    <div className='radio-button-container'>
                      <div className={'radio-button '+`${IsUsed == 1 ? "clicked":""}`} onClick={()=>{setIsUsed(1)}}></div>
                      <p>해당없음</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='cpo-charger-model-enroll-modal-content-wrapper marginTop'>
              <p>상세사양</p>
              <div className="cpo-charger-model-enroll-modal-content-textarea-container">
                <textarea type="text" placeholder='상세사양을 입력해주세요' value={DetailedSpec} onChange={(e)=>{setDetailedSpec(e.target.value)}} />
              </div>
            </div>
          </div>
          
          
          

        </div>
        <div className='cpo-charger-model-enroll-modal-button-container'>
          <div className="cpo-charger-model-enroll-modal-button">등록하기</div>
        </div>
      </div>
    </div>
  )
}
export default CpoChargerModelEnrollModal

import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { setCookie, getCookie } from '../../util/util';
import { CiWarning } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";


export const PaymentFunctionModal = (props) => {
  const signedToken =  getCookie('signedToken')

  const [name, setName] = useState(null);

  const deleteCard = async()=>{
    axios.delete(`/payment/api/PaymentCards/${props.data.paymentCardId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res)
      props.getCardList();
      props.setOnOff(false);
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  const updateCard = async()=>{
    // axios.put(`/payment/api/PaymentCards/${props.data.paymentCardId}`,{
    //   cardName : name
    // },{
    //   headers: {
    //     Authorization: `Bearer ${signedToken}`
    //   }
    // })
    // .then((res)=>{
    //   console.log(res)
    // })
    // .catch((err)=>{
    //   console.log(err)
    // })
  }
  return (
    <div className="cpo-payment-function-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-payment-function-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-payment-function-modal-header">
          <title>{props.data.category == 'delete'? "":"카드명 수정"}</title>
          <div className='cpo-payment-function-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        {
          props.data.category == 'delete'?
          <>
            <div className='cpo-payment-function-modal-content delete'>
              <div className='cpo-payment-function-modal-delete-alert'>
                <CiWarning/>
              </div>
              <div className='cpo-payment-function-modal-delete-msg'><p>카드를 삭제하시겠습니까?</p></div>
              <div className='cpo-payment-function-modal-delete-info'><p>{props.data.name+" ("+props.data.number+")"}</p></div>
              <div className='cpo-payment-function-modal-delete-button' onClick={(e)=>{e.stopPropagation();e.preventDefault(); deleteCard();}}><p>삭제</p></div>
            </div>
          </>
          :
          <>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
            <div className='cpo-payment-function-modal-content edit'>
              <div className='cpo-payment-function-modal-edit-info'><p>{props.data.name+" ("+props.data.number+")"}</p></div>
              <div  className='cpo-payment-function-modal-edit-title'>
                <p>변경할 카드명</p>
              </div>
              <div className='cpo-payment-function-modal-edit-input-container'>
                <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} />
              </div>
              <div className={'cpo-payment-function-modal-edit-button ' + `${!name ? "disable":""}`}>
                <p>저장하기</p>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}
export default PaymentFunctionModal

import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import Selector2 from '../buttons/Selector2';
import { getCookie } from '../../util/util';
import { CiWarning } from "react-icons/ci";

export const ChasDeleteModal = (props) => {
  let signedToken = getCookie("signedToken");



  
  const deleteActionSet = async()=>{
    axios.delete(`/controlhub/api/cpouser/v1/ActionSets/${props.data.action.actionSetId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      props.getCHASList();
      props.setOnOff(false);
    })
    .catch((err)=>{
      
    })
  }
  const deleteMessage = async()=>{
    for await(const item of props.data.message.map){
      axios.delete(`/controlhub/api/cpouser/v1/ActionSets/${props.data.action.actionSetId}/MessageActionMaps/${item.messageActionMapId}`,{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
    }
    props.getCHASList();
    props.setOnOff(false);
  }
  const deleteChas = ()=>{
    if(props.content == "message") deleteMessage();
    else deleteActionSet();
  }
  return (
    <div className="cpo-chas-delete-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-chas-delete-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-chas-delete-modal-header">
          <title>CHAS {props.content == "message"?"메세지":"액션셋"} 제거</title>
        </div>
        <div className='cpo-chas-delete-modal-content'>
          {
            props.content == "message"?
            <>
              <>
                <div className='cpo-payment-function-modal-content delete'>
                  <div className='cpo-payment-function-modal-delete-alert'>
                    <CiWarning/>
                  </div>
                  <div className='cpo-payment-function-modal-delete-msg'><p>메세지를 삭제하시겠습니까?</p></div>
                  <div className='cpo-payment-function-modal-delete-info'><p>{props.data.message.name}</p></div>
                </div>
              </>
            </>
            
            :
            <>
              <div className='cpo-payment-function-modal-content delete'>
                <div className='cpo-payment-function-modal-delete-alert'>
                  <CiWarning/>
                </div>
                <div className='cpo-payment-function-modal-delete-msg'><p>액션셋을 삭제하시겠습니까?</p></div>
                <div className='cpo-payment-function-modal-delete-info'><p>{props.data.action.name}</p></div>
              </div>
            </>
          }
          <div className='cpo-payment-function-modal-delete-button' onClick={(e)=>{e.stopPropagation();e.preventDefault(); deleteChas();}}><p>삭제</p></div>

        </div>
        

      </div>
    </div>
  )
}
export default ChasDeleteModal

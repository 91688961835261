import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate } from "react-router-dom";
import { getCookie } from '../../util/util.js';
import SubscriptionEnrollModal from '../../components/modals/SubscriptionEnrollModal.js';
import SubscriptionProductionCard from '../../components/list/SubscriptionProductionCard.js';
import SubscriptionEnrolledCard from '../../components/list/SubscriptionEnrolledCard.js';
import SubscriptionExpiredCard from '../../components/list/SubscriptionExpiredCard.js';

const CpoSubscription = (props) => {
  let navigator = useNavigate();

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");  
  // 진입 시 redux에 page 적용
  useEffect(()=>{
    dispatch(setNum({id:7, idx:0}));
  },[])

  // 현재 계정의 구독 리스트 호출
  const [currentSubList, setCurrentSubList] = useState([]);
  // 현재 계정의 구독 가능한 리스트 호출
  const [futureSubList, setFutureSubList] = useState([]);

  // 현재 계정의 구독 리스트 호출
  const getCurrentSubscriptionList = async()=>{
    await axios.get(`/subscription/api/cpoadmin/Subscriptions`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data);
      let temp = [...res.data];
      setCurrentSubList(temp);
    })
    .catch((err)=>{
      console.log(err);
    })
  }
  // 현재 계정의 구독 가능한 리스트 호출
  const getFutureSubscriptionList = async()=>{
    await axios.get(`/subscription/api/cpoadmin/SubscriptionProducts`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data);
      let temp = [...res.data];
      setFutureSubList(temp);
    })
    .catch((err)=>{
      console.log(err);
    })
  }
  
  useEffect(()=>{
    getCurrentSubscriptionList();
    getFutureSubscriptionList();
  },[])

  

  const [flip, setFlip] = useState(false);
  const [modalOn, setModalOn] = useState(false);
  const [subscriptionItem, setSubscriptionItem] = useState(null);

  const [tempData, setTempData] = useState(null);
  // 클릭한 탭 인덱스
  const tabBtnRef = useRef([]);
  const [tabIdx, setTabIdx] = useState(0)
  // 클릭 이벤트 함수
  const tabClick = (idx)=>{
    if(tabIdx !== idx){
      setTabIdx(idx);
    }
  }
  return (
    <div className='cpo-system-setting-container'>
        {/* <div className='cpo-subscription-divider'></div> */}
      {modalOn&&subscriptionItem&&<SubscriptionEnrollModal setOnOff={setModalOn} getCurrentSubscriptionList={getCurrentSubscriptionList} data={subscriptionItem} setCurrentSubList={setCurrentSubList} />}
      <div className="cpo-system-setting-inner">
        <div className="cpo-subscription-page-container">
          <div className='cpo-subscription-tab-container'>
            <div ref={el=>tabBtnRef.current[0]=el} className={tabIdx == 0 ? "subscription-tab-button selected":"subscription-tab-button"} onClick={()=>{tabClick(0);}}><p>구독 가능 상품</p></div>
            <div ref={el=>tabBtnRef.current[1]=el} className={tabIdx == 1 ? "subscription-tab-button selected":"subscription-tab-button"} onClick={()=>{tabClick(1)}}><p>현재 구독 중</p></div>
            <div ref={el=>tabBtnRef.current[2]=el} className={tabIdx == 2 ? "subscription-tab-button selected":"subscription-tab-button"} onClick={()=>{tabClick(2)}}><p>구독 이력</p></div>
            <div className={'subscription-tab-bar '+'tab'+tabIdx}></div>
          </div>
          <div className="cpo-current-subscription-section">
            {tabIdx == 0 ?
              <div className="cpo-current-subscription-item-container">
              {
                futureSubList&&futureSubList.map((it, idx)=>{
                  return(
                    <>
                      <SubscriptionProductionCard setModalOn={setModalOn} data={it} setSubscriptionItem={setSubscriptionItem} />
                    </>
                  )
                })
              }
              </div>
              :tabIdx == 1 ?
              <>
                <div className='cpo-enrolled-subscription-item-container'>
                {
                  currentSubList&&futureSubList&&currentSubList.map((it, idx)=>{
                    console.log(futureSubList);
                    return(
                      <SubscriptionEnrolledCard data={it} getCurrentSubscriptionList={getCurrentSubscriptionList} detailedInfo={futureSubList.filter(item=>it.subscriptionProduct.normalizedName == item.normalizedName)[0]}/>
                    )
                  })
                }
                </div>
              </>
              :tabIdx == 2 ?
              <>
                <div className='cpo-enrolled-subscription-item-container'>
                {
                  currentSubList&&futureSubList&&currentSubList.map((it, idx)=>{
                    console.log(futureSubList);
                    return(
                      <SubscriptionExpiredCard data={it} getCurrentSubscriptionList={getCurrentSubscriptionList} detailedInfo={futureSubList.filter(item=>it.subscriptionProduct.normalizedName == item.normalizedName)[0]}/>
                    )
                  })
                }
                </div>
              </>
              :
              <></>
            }


          </div>

        </div>
      </div>
    </div>
  )
}
export default CpoSubscription;
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { setCookie, getCookie, numberInput } from '../../util/util';

import { MdInfoOutline } from "react-icons/md";
import { PiSlidersHorizontal } from "react-icons/pi";
import { FaSearch } from "react-icons/fa";


import ChargingFeeStationItem from '../../components/list/ChargingFeeStationItem.js';
import ChargingFeeSettingModal from '../../components/modals/ChargingFeeSettingModal.js';
import ChargingFeeSearchModal from '../../components/modals/ChargingFeeSearchModal.js';



const CpoChargingFeeManagement = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  useEffect(()=>{
    dispatch(setNum({id:2, idx:1}));
  },[])


  // 충전소 데이터 Array<Object>
  const [stationInfo, setStationInfo]=useState(null);
  // 충전기 데이터 Array<Object>
  const [chargerInfo, setChargerInfo]=useState(null);
  // 충전기 controlhub 데이터 Array<Object>
  const [chargerHubInfo, setChargerHubInfo]=useState(null);

  useEffect(()=>{
    getDefaultRate();
    getChargerRateList();
    getStationList();
  },[])


  // cpo가 소유한 station GET
  const getStationList = async()=>{  
    await axios.get(`/infra/api/cpouser/v1/ChargingStations`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      let temp = [...res.data.Results];
      setStationInfo(temp);
    })
  }

  // chargerHub 정보 API GET
  const getChargerHubList = async()=>{
    let temp = [];
    for await(const it of stationInfo){
      await axios.get(`/controlhub/api/cpouser/v1/Chargers?chargingStationId=${it.ChargingStationId}`,{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        temp.push(res.data);
      })
    }
    setChargerHubInfo(temp);
    setStation(stationInfo[0])
  }

  // charger 정보 API GET
  const getChargerList = async()=>{
    let temp = [];
    for await(const it of stationInfo){
      await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${it.ChargingStationId}`,{
        headers: {
          Authorization: `Bearer ${signedToken}`
        }
      })
      .then((res)=>{
        temp.push(res.data);
      })
    }
    console.log(temp);
    setChargerInfo(temp);
  }

  // station 데이터 받은 후 chargerHub, charger 정보 GET 트리거
  useEffect(() => {
    if(!stationInfo)return;
    getChargerHubList();
    getChargerList();
  }, [stationInfo])




  const[chargerRateList, setChargerRateList] = useState([]);
  const [membershipRate, setMembershipRate] = useState();
  const [nonMembershipRate, setNonMembershipRate] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [checked, setChecked] = useState(false);

  
  // 기본 설정 단가 get
  let getDefaultRate = async()=>{
    await axios.get(`/chargerate/api/cpouser/DefaultRate`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      setMembershipRate(res.data.membershipRate);
      setNonMembershipRate(res.data.nonMembershipRate);
    })
  }
  
  let getChargerRateList = async()=>{
    await axios.get(`/chargerate/api/cpouser/Chargers`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      setChargerRateList(res.data);
    })
  }
  const regionList = [
    {name: 'A(서울, 인천, 경기)'},
    {name: 'B(충청, 전라)'},
    {name: 'C(강원, 경상)'},
    {name: 'D(제주)'},
    // {name: '미분류'},
  ];

  const [Region, setRegion] = useState('');
  const [RegionActive, setRegionActive] = useState(null);
  const [Station, setStation] = useState('');
  const [StationActive, setStationActive] = useState(null);
  const activeReset = ()=>{
    setRegionActive(false);
    setStationActive(false);
  }


  const [modalOff, setModalOff] = useState(false);

  const [moreInfoOn, setMoreInfoOn] = useState(false);


  let postDefaultRate = async()=>{
    await axios.post(`/chargerate/api/cpouser/DefaultRate`,
    {
      "membershipRate" : parseInt(membershipRate),
      "nonMembershipRate" : parseInt(nonMembershipRate),
    },
    {
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      
    })
  }

  const [searchModalOn, setSearchModalOn] = useState(false);


  return (
    <div className="cpo-charging-fee-management-page-container">
      {modalOff &&
        <ChargingFeeSettingModal getChargerRateList={getChargerRateList} chargerRateList={chargerRateList} stationInfo={stationInfo} chargerInfo={chargerInfo} chargerHubInfo={chargerHubInfo} setOnOff={setModalOff} 
      />}

      <div className="cpo-charging-fee-management-inner">
        <div className="cpo-charging-fee-management-container">
          <div>
            <div className="cpo-charging-fee-management-header">
              <div className='cpo-charging-fee-management-more-info'>more info<MdInfoOutline onClick={()=>{setMoreInfoOn(!moreInfoOn)}}/></div>
              {moreInfoOn&&<ChargingFeeMoreInfo/>}
              <div className='cpo-charging-fee-management-title-container'>
                <title>기본 충전 요금</title>
                <p>(원 kWh, 부가세 포함)</p>
              </div>
              <div className='cpo-charging-fee-management-event-period-setting-button' onClick={()=>{setModalOff(true)}}><p>이벤트 기간 설정</p></div>
            </div>

            <div className="cpo-charging-fee-management-table-container">
              <div className="cpo-charging-fee-management-table">
                <div className='cpo-charging-fee-management-table-main-title'>회원가</div>
                <div className='cpo-charging-fee-management-table-title column'>여름철(6월~8월)</div>
                <div className='cpo-charging-fee-management-table-title column'>봄/가을철</div>
                <div className='cpo-charging-fee-management-table-title column'>겨울철(11월~2월)</div>

                <div className='cpo-charging-fee-management-table-title'>경부하시간대</div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={membershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={membershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={membershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setMembershipRate(e.target.value)}}/>
                </div>

                <div className='cpo-charging-fee-management-table-title'>중간부하시간대</div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={membershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={membershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={membershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setMembershipRate(e.target.value)}}/>
                </div>
                
                <div className='cpo-charging-fee-management-table-title'>최대부하시간대</div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={membershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={membershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={membershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setMembershipRate(e.target.value)}}/>
                </div>
              </div>
              <div className='cpo-charging-fee-management-function-container'>
                <p>자유롭게 클릭하여 요금을 변경해보세요</p>
                <div className='cpo-charging-fee-management-function-button cancel'>취소</div>
                <div className='cpo-charging-fee-management-function-button save' onClick={()=>{postDefaultRate();}}>저장</div>
              </div>
            </div>
            
            <div className="cpo-charging-fee-management-table-container">
              <div className="cpo-charging-fee-management-table">
                <div className='cpo-charging-fee-management-table-main-title'>비회원가</div>
                <div className='cpo-charging-fee-management-table-title'>여름철(6월~8월)</div>
                <div className='cpo-charging-fee-management-table-title'>봄/가을철</div>
                <div className='cpo-charging-fee-management-table-title'>겨울철(11월~2월)</div>

                <div className='cpo-charging-fee-management-table-title'>경부하시간대</div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={nonMembershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setNonMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={nonMembershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setNonMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={nonMembershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setNonMembershipRate(e.target.value)}}/>
                </div>

                <div className='cpo-charging-fee-management-table-title'>중간부하시간대</div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={nonMembershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setNonMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={nonMembershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setNonMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={nonMembershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setNonMembershipRate(e.target.value)}}/>
                </div>
                
                <div className='cpo-charging-fee-management-table-title'>최대부하시간대</div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={nonMembershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setNonMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={nonMembershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setNonMembershipRate(e.target.value)}}/>
                </div>
                <div className='cpo-charging-fee-management-table-cell'>
                  <input type="text" value={nonMembershipRate} onInput={(e)=>{numberInput(e.target)}} onChange={(e)=>{setNonMembershipRate(e.target.value)}}/>
                </div>
              </div>
              <div className='cpo-charging-fee-management-function-container'>
                <p>자유롭게 클릭하여 요금을 변경해보세요</p>
                <div className='cpo-charging-fee-management-function-button cancel'>취소</div>
                <div className='cpo-charging-fee-management-function-button save' onClick={()=>{postDefaultRate();}}>저장</div>
              </div>
            </div>
            
          </div>
          <div>
            <div className='cpo-charging-fee-management-title-container'>
              <title>충전소 찾기</title>
            </div>
            <div className="cpo-charging-fee-management-search-container">
              {searchModalOn && <ChargingFeeSearchModal/>}
              <div className="cpo-charging-fee-setting-modal-search-filter-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault();setSearchModalOn(!searchModalOn)}}>검색필터<PiSlidersHorizontal/></div>
              <div className="cpo-charging-fee-setting-modal-search-input-container">
                <input type="text" placeholder={'충전소 이름으로 검색하기'} value={searchKeyword} onChange={(e)=>{setSearchKeyword(e.target.value)}}/>
                <FaSearch/>
              </div>
            </div>
            <div className='cpo-charging-fee-station-table'>
              <div className='cpo-charging-fee-station-table-column'>
                <div className='cpo-charging-fee-station-table-column-item'><p>충전소명</p></div>
                <div className='cpo-charging-fee-station-table-column-item'><p>요금적용상태</p></div>
                <div className='cpo-charging-fee-station-table-column-item'><p>무료 운영기간</p></div>
                <div className='cpo-charging-fee-station-table-column-item'><p>할인기간</p></div>
              </div>
              <div className='cpo-charging-fee-station-item-list'>
                {
                  stationInfo?.map((ia,idx)=>{
                    return(
                      <ChargingFeeStationItem data={ia} />
                  )
                  })
                }
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}
export default CpoChargingFeeManagement;


const ChargingFeeMoreInfo = ()=>{
  return(
    <>
      <div className='cpo-charging-fee-more-info-modal-container'>
        <div className="cpo-charging-fee-management-table">
          <div className='cpo-charging-fee-management-table-main-title'>회원가</div>
          <div className='cpo-charging-fee-management-table-title column'>여름철(6월~8월)</div>
          <div className='cpo-charging-fee-management-table-title column'>봄/가을철</div>
          <div className='cpo-charging-fee-management-table-title column'>겨울철(11월~2월)</div>

          <div className='cpo-charging-fee-management-table-title'>경부하시간대</div>
          <div className='cpo-charging-fee-management-table-cell'>23~09시</div>
          <div className='cpo-charging-fee-management-table-cell'>23~09시</div>
          <div className='cpo-charging-fee-management-table-cell'>23~09시</div>

          <div className='cpo-charging-fee-management-table-title'>중간부하시간대</div>
          <div className='cpo-charging-fee-management-table-cell'>09~10시, 12~13시, 17~23시</div>
          <div className='cpo-charging-fee-management-table-cell'>09~10시, 12~13시, 17~23시</div>
          <div className='cpo-charging-fee-management-table-cell'>09~10시, 12~17시, 20~22시</div>
          
          <div className='cpo-charging-fee-management-table-title'>최대부하시간대</div>
          <div className='cpo-charging-fee-management-table-cell'>10~12시, 13~17시</div>
          <div className='cpo-charging-fee-management-table-cell'>10~12시, 13~17시</div>
          <div className='cpo-charging-fee-management-table-cell'>10~12시, 17~20시, 22~23시</div>
        </div>
      </div>
    </>
  );
}
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate, useLocation } from "react-router-dom";

import { getCookie, refresh } from '../../util/util.js';


import { FaRegQuestionCircle } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";

import { MdSearch } from "react-icons/md";

import SearchSelector from '../../components/buttons/SearchSelector.js';
import {FaChevronUp, FaChevronRight,  FaChevronDown, FaChevronLeft} from "react-icons/fa6";

export const CpoChasEdit2 = (props) => {
  let navigator = useNavigate();
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");
  const location = useLocation();
  const data = location.state.data;
  const idData = location.state.idData.filter(it=>it.startsWith("DATA_TRANSFER_REQ_"));
  const message = location.state.message;
  // const getConnectionList = location.state.getConnectionList;
  
  useEffect(()=>{
    dispatch(setNum({id:7, idx:1, detail:true}));
    console.log(idData)
    getConnectionList();
  },[])
  
  // props.idData.

  const [actionSelect, setActionSelect] = useState();
  const [actionActive, setActionActive] = useState();
  const activeReset = async()=>{
    setActionActive(false);
  }

  const [chasDataModalOn, setChasDataModalOn] = useState(false);


  // /api/cpouser/v1/ActionSets/{data?.actionSetId}/AUTHORIZE_REQ --메세지 추가

  // /controlhub/api/ControlHubActions --액션들 불러오기

  const [actionList, setActionList] = useState();
  
  const getActions = async()=>{
    axios.get('/controlhub/api/ControlHubActions',{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res);
      // let temp = res.data.filter(it=>)
      setActionList(res.data);
    })
  }


  const [actionOfMessageList, setActionOfMessageList] = useState();
  const [actionOrderList, setActionOrderList] = useState();

  const [dataLabel, setDataLabel] = useState();
  const [ConnectionKeys, setConnectionKeys] = useState([]);

  useEffect(()=>{
    setConnectionKeys(idData)
  },[])


  const getConnectionList = async()=>{
    await axios.get(`/controlhub/api/cpouser/v1/ActionSets/${data?.actionSetId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data);
      
      let connectionListTemp = [...res.data.messageControlHubActions];
      let msgTemp = {};

      for (const item of connectionListTemp){
        // 메세지 종류 확인
        let msg = '';
        if(item.messageType == 'DATA_TRANSFER_REQ'){
          msg = item.messageType + "_" + item.dataTransferMessageId;
        }
        else{
          msg = item.messageType
        }

        // 메세지 종류 == key 인 object 생성
        if(!(msgTemp[msg])){
          msgTemp[msg] = [item];
        }
        else{
          msgTemp[msg] = [...msgTemp[msg],item];
        }
      }
      console.log(msgTemp);
      setConnectionKeys(Object.keys(msgTemp).filter(it=>it.startsWith("DATA_TRANSFER_REQ_")));
    })
    .catch((err)=>{
      
    })
  }

  useEffect(()=>{
    getActionOfMessageList()
  },[dataLabel?.label])

  const getActionOfMessageList = async()=>{
    if(!dataLabel?.label)return;
    axios.get(`/controlhub/api/cpouser/v1/ActionSets/${data?.actionSetId}/${"DATA_TRANSFER_REQ"}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data);
      setActionOfMessageList(res.data.filter(it=>it.dataTransferMessageId == dataLabel?.label));
    })
  }

  useEffect(()=>{
    console.log(data);
    getActions();
  },[])
  
  const [labelList, setLabelList] = useState();

  useEffect(()=>{
    if(ConnectionKeys){
      getLabelList();
    }
  },[ConnectionKeys])

  const getLabelList = ()=>{
    let temp = [];
    ConnectionKeys.map((it, idx)=>{
      let val = it.replace("DATA_TRANSFER_REQ_","");
      temp.push({value: val, label: val});
    })
    setLabelList(temp);
  }
  useEffect(()=>{
    if(message){

      let val = labelList?.filter(it=>it.label == message.replace("DATA_TRANSFER_REQ_",""))[0];
      console.log(labelList);
      console.log(val);
      setDataLabel(val)
    }
  },[labelList])
  const [dataTransferMessageId, setDataTransferMessageId] = useState();



  const putActionOfMessageList = async(actionList)=>{
    if(!dataLabel?.label)return;
    axios.put(`/controlhub/api/cpouser/v1/ActionSets/${data?.actionSetId}/DATA_TRANSFER_REQ`,{
      "dataTransferMessageId" : dataLabel?.label,
      "controlHubActionInfoIds": actionList
    },{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res);
      getActionOfMessageList()
      getConnectionList()
    })
  }






  const [rightSelected, setRightSelected] = useState(null);
  const [leftSelected, setLeftSelected] = useState(null);

  // 버튼 활성화 state
  const [moveRight, setMoveRight] = useState(false);
  const [moveLeft, setMoveLeft] = useState(false);
  const [moveDisable, setMoveDisable] = useState();
  useEffect(()=>{
    if(rightSelected !== null){
      setMoveRight(false);
      setMoveLeft(true);
    }
    else if(leftSelected !== null){
      if(moveDisable){
        setMoveRight(false);
      }
      else setMoveRight(true);
      setMoveLeft(false);
    }
  },[rightSelected, leftSelected])

  const moveClick = async(direction)=>{
    if(direction == "left"){
      putActionOfMessageList([]);
      setMoveRight(false);
      setMoveLeft(false);
    }
    else if(direction == "right"){ // 현재 액션리스트로 추가
      let temp = [actionList[leftSelected].controlHubActionId];
      putActionOfMessageList(temp);
      setMoveRight(false);
      setMoveLeft(false);
    }
  }





  return (
    <div className='cpo-system-setting-container'>

      <div className="cpo-system-setting-inner chas">
      <div className="cpo-chas-content-container">
        <div className='cpo-chas-navigator' onClick={()=>{navigator("/cpo/chas")}}>
          <FaChevronLeft/>뒤로가기
        </div>
        <div className='cpo-chas-content-header'>
          <div className='cpo-chas-data-container'><p>CHAS DATA</p><FaRegQuestionCircle onClick={()=>{setChasDataModalOn(!chasDataModalOn);}}/></div>
          {(chasDataModalOn)?
            <div className='cpo-chas-data-modal edit'>
              <p>{data?.description}</p>
              <p>{`${data?.createdDate} / ${data?.lastModifiedDate} / ${'1.0.0'} / ${'0.0.0'}`}</p>
            </div>
            :<></>
          }

          <div className='cpo-chas-title-container'>
            <p>{data?.name}</p>
            <p className='cpo-chas-edit-info'>DataTransfer 메세지 편집</p>
          </div>
        </div>

        <div className="cpo-chas-edit-data-transfer-block-container">
          <p>Data Transfer Message Action Binding</p>
          <div className='cpo-chas-edit-data-transfer-wrapper'>
            <div className='cpo-chas-edit-data-transfer-container'>
              <p onClick={()=>{console.log(dataLabel);}}>데이터 식별명</p>
              <div className='cpo-chas-edit-data-transfer-input-container'>
                {/* <input type="text" placeholder='식별명을 입력하세요' value={dataTransferMessageId} onChange={(e)=>{setDataTransferMessageId(e.target.value)}}/> */}
                <SearchSelector placeholder="식별명을 입력 및 선택하세요" options={labelList} setDataLabel={setDataLabel} value={dataLabel} />
              </div>
            </div>
          </div>
          
        </div>
        
        
        <div className='cpo-chas-action-edit-block-container'>
          <div className='cpo-chas-title-container edit'>
            <p>{data?.name}</p>
            <FaChevronRight/>
            <p>{dataLabel?.label}</p>
          </div>
        </div>
        <div className='cpo-chas-action-grid-container'>
          <div className='cpo-chas-action-edit-container'>
            <div className='cpo-chas-action-edit-title-container'>Actions</div>
            <div className='cpo-chas-action-edit-search-container'>
              <MdSearch/>
              <input type="text" placeholder='키워드로 검색하세요'/>
            </div>
            <div className='cpo-chas-action-edit-item-list'>
            {
              actionList?.map((it, idx)=>{
                if(actionOfMessageList?.filter(action=>action?.chControlHubAction?.name == it.name).length !== 0){
                  return;
                }

                return(
                  <>
                    <div className={'cpo-chas-action-edit-item left '+`${leftSelected==idx?"selected":""}`} onClick={()=>{setLeftSelected(idx); setRightSelected(null) }}>{it.name}</div>
                  </>
                )
              })
            }
            </div>
          </div>
          <div className='cpo-chas-action-edit-four-button-container'>
            <div className={'cpo-chas-action-edit-move-button ' + `${moveRight ? "activate" : ""}` } onClick={()=>{moveClick('right')}}><FaChevronRight/></div>
            <div className={'cpo-chas-action-edit-move-button ' + `${moveLeft ? "activate" : ""}` } onClick={()=>{moveClick('left')}}><FaChevronLeft/></div>
          </div>
          <div className='cpo-chas-action-edit-container'>
            <div className='cpo-chas-action-edit-title-container'>Binded Action</div>
            <div className='cpo-chas-action-edit-search-container'>
              <MdSearch/>
              <input type="text" placeholder='키워드로 검색하세요'/>
            </div>
            <div className='cpo-chas-action-edit-item-list'>
            {
              actionOfMessageList?.map((it, idx)=>{
                return(
                  <>
                    <div className={'cpo-chas-action-edit-item right '+`${rightSelected==idx?"selected":""}`} onClick={()=>{setRightSelected(idx); setLeftSelected(null);}}>
                      <p>{it.order}</p>
                      <p>{it.chControlHubAction?.name}</p>
                    </div>
                  </>
                )
              })
            }
            </div>
          </div>
        </div>


        </div>
      </div>
    </div>
  )
}
export default CpoChasEdit2;
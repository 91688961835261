import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { setCookie, removeCookie, getCookie } from '../../util/util';

import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

export const Login = () => {
  let navigator = useNavigate();
  let signedToken = getCookie("signedToken");
  useEffect(()=>{
    if(signedToken){
      navigator('/cpo')
    }
  },[])
  
  const [passwordVisible, setPasswordVisible] = useState(false);

  const signedExpires = new Date();
  const refreshExpires = new Date();

  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const requestLogin = async()=>{
    console.log(email, password, checked);
    if(!email||!password){
      return;
    }
    await axios.post("/account/api/NearCpoAuth/signin", 
    {
      "email": email,
      "password": password,
      "rememberMe": checked
    },{withCredentials : true})
    .then(async(res)=>{
      console.log(res);
      setCookie('signedToken', res.data.SignedToken, {
        path: '/',
        expires: new Date(res.data.ExpiryTime)
      });
      localStorage.setItem('LocalRealName',res.data.LocalRealName)
      localStorage.setItem('user-email',email)
    })
    .then((res)=>{
      navigator('/cpo')
      }
    )
    .catch((err)=>{
      console.log(err);
      // 이메일 미인증
      if(err.response?.data.ErrorCode == "EMAIL_VERIFICATION_REQUIRED"){
        navigator("/email-verification", {state:{email:email}})
      }
    })
  }

  return (
    <div className='login-page-container'>
      <div className="login-page-wrapper">
        <title>로그인</title>
        <p className="login-input-title">이메일</p>
        <div className='login-input-container email'><input placeholder='이메일을 입력하세요' type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/></div>
        <p className="login-input-title">비밀번호</p>
        <div className='login-input-container password'><input placeholder='비밀번호를 입력하세요' type={passwordVisible ? "text" : "password"} autocomplete="new-password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/> {passwordVisible ? <IoMdEyeOff onClick={()=>setPasswordVisible(false)}/> : <IoMdEye onClick={()=>setPasswordVisible(true)}/>}</div>
        <div className='login-function-container'>
          <div className='login-checkbox-container'>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon login"></span>
            </label>
            <p>아이디 저장</p>
          </div>
          <div className='login-found-account-container' onClick={(e)=>{removeCookie('refreshToken');}}>
            <p>이메일/비밀번호찾기</p>
          </div>
        </div>
        <div className='login-button' onClick={(e)=>{e.stopPropagation();e.preventDefault();requestLogin();}}><p>로그인</p></div>
        <div className='sign-up-info-container'>
          <p>아직  EMOOV의 회원이 아니신가요?</p>
          <p onClick={(e)=>{e.stopPropagation();e.preventDefault();navigator("/sign-up");}}>회원가입</p>
        </div>
        <div className='login-go-to-simple-button' onClick={(e)=>{e.stopPropagation();e.preventDefault();navigator("/simple-login");}}><p>간편로그인으로 전환하기</p></div>
      </div>
    </div>
  )
}
export default Login;
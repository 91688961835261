import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import {setNum} from '../../store.js'
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';
import { IoMdAddCircleOutline } from "react-icons/io";
import PaymentCardEnrollModal from '../../components/modals/PaymentCardEnrollModal.js';
import PaymentItem from '../../components/list/PaymentItem.js';
import PaymentFunctionModal from '../../components/modals/PaymentFunctionModal.js';


const CpoPaymentMethod = (props) => {
  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setNum({id:8, idx:0}));
    getCardList();
  },[])
  const signedToken =  getCookie('signedToken')
  
  const getCardList = async(id)=>{
    await axios.get(`/payment/api/cpouser/PaymentCards`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data);
      setCardList([...res.data]);
    })
  }
  const [cardList, setCardList] = useState([]);

  const [enrollModalOn, setEnrollModalOn] = useState(false);
  const [functionModalOn, setFunctionModalOn] = useState(false);
  const [functionRequest, setFunctionRequest] = useState(null);
  return (
    <div className="cpo-account-management-container">
      {enrollModalOn&&<PaymentCardEnrollModal getCardList={getCardList} setOnOff={setEnrollModalOn}/>}
      {functionModalOn&&<PaymentFunctionModal setOnOff={setFunctionModalOn} data={functionRequest} getCardList={getCardList}/>}
      <div className="cpo-account-management-inner">
        <div className="cpo-payment-method-container">
          <div className="cpo-payment-method-header">
            <div className='cpo-payment-method-title'>
              <p>
                등록된 카드
              </p>
              <p>{cardList.length}</p>
            </div>
            
            <div className='cpo-payment-method-add-button'onClick={()=>{setEnrollModalOn(true)}}><IoMdAddCircleOutline/><p>카드 등록하기</p></div>
          </div>
        </div>
        <div className="cpo-payment-method-content">
          <div className='cpo-payment-method-content-title'><p>기본 결제 카드</p></div>
          {
            cardList.map((it,idx)=>{
              if(!it.isDefault)return;
              return(
                <PaymentItem data={it} setFunctionModalOn={setFunctionModalOn} setFunctionRequest={setFunctionRequest}/>
              )
            })
          }
          <div className='cpo-payment-method-content-title second'><p>결제 카드</p></div>
          {
            cardList.map((it,idx)=>{
              if(it.isDefault)return;
              return(
                <PaymentItem data={it} setFunctionModalOn={setFunctionModalOn} setFunctionRequest={setFunctionRequest}/>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
export default CpoPaymentMethod;

/*
acquirerCode

cardType
createdDate
isDeleted
isValid
issuerCode
modifiedDate

name
number
ownerType
paymentCardId
paymentCardStatus
paymentGatewayType
*/
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { chargerTypeConverter, connectorTypeConverter } from '../../util/util';
import { FaChevronDown } from "react-icons/fa6";


export const CpoChargerModelListItem = (props) => {
  
  const [tabOpen, setTabOpen] = useState(false);
  const [checked, setChecked] = useState(null);

  useEffect(()=>{
    if(props.deleteMode){
      setTabOpen(false);
    }
    else{
      setTabOpen(false);

      setChecked(false);
    }
  },[props.deleteMode])

  return (
    <div className={'cpo-charger-model-management-content-item-container '+`${tabOpen ? "open":""}`}>
      <div className={'cpo-charger-model-management-content-item '+`${props.deleteMode?"deleteMode ":""}` +`${checked?"checked":""}`} onClick={()=>{setTabOpen(!tabOpen)}}>
        {
          !props.deleteMode ?
          <>
            <div><p>{props.data.Manufacturer.Company.Name}</p></div>
            <div><p>{props.data.Name}</p></div>
            <div><p>{chargerTypeConverter(props.data.ChargerType)}</p></div>
            <div><p>{props.data.ChargingSpeed}</p></div>
            <div><p>{props.data.ChargingPower}</p></div>
            <div><p>{props.data.Voltage}</p></div>
            <div><p>{props.data.ElectricCurrent}</p></div>
            <div><p>{connectorTypeConverter(props.data.ConnectorType)}</p></div>
            <div><p>{new Date(props.data.ActualRegistrationDate).getUTCFullYear() +"/"+ new Date(props.data.ActualRegistrationDate).getMonth()}</p></div>
            <div><p></p></div>
            <div><p>{tabOpen ? "닫기":"펼치기"}</p><FaChevronDown/></div>
          </>
          :
          <>
          <div>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon"></span>
            </label>
          </div>
          <div><p>{props.data.Manufacturer.Company.Name}</p></div>
          <div><p>{props.data.Name}</p></div>
          <div><p>{chargerTypeConverter(props.data.ChargerType)}</p></div>
          <div><p>{props.data.ChargingSpeed}</p></div>
          <div><p>{props.data.ChargingPower}</p></div>
          <div><p>{props.data.Voltage}</p></div>
          <div><p>{props.data.ElectricCurrent}</p></div>
          <div><p>{connectorTypeConverter(props.data.ConnectorType)}</p></div>
          <div><p>{new Date(props.data.ActualRegistrationDate).getUTCFullYear() +"/"+ new Date(props.data.ActualRegistrationDate).getMonth()}</p></div>
          <div><p></p></div>
        </>
        }
        
      </div>
      <div className='cpo-charger-model-management-content-item-content'>
        <div><p>설명</p>{props.data.Remarks}</div>
        <div><p>상세사양</p>{props.data.DetailedSpec}</div>
      </div>
    </div>
  )
}
export default CpoChargerModelListItem;
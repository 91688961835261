import React, { useRef, useState, useEffect } from 'react';


export const CpoGroupItem = (props) => {
  const [checked, setChecked] = useState();
  return (
    <div className={"cpo-group-management-content-item " + `${props.deleteMode?"deleteMode ":""}` +`${checked ? "checked ":""}`}>
      {
        props.deleteMode && 
        <>
          <div>
            <label className="checkbox_label" onClick={(e)=>{e.stopPropagation();}}>
              <input type="checkbox" checked={checked} onChange={()=>{setChecked(!checked)}} />
              <span className="checkbox_icon"></span>
            </label>
          </div>
        </>
      }
      <div><p>{props.data.email}</p></div>
      <div>
        <div className={'cpo-group-management-content-item-status ' + `${props.data.status?"true":""}`}>
          <p>{props.data.status?"수락":"미수락"}</p>
        </div>
      </div>
    </div>
  )
}
export default CpoGroupItem;
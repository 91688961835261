import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import {chargerTypeConverter, connectorTypeConverter} from "../../util/util.js"


const ChargerCardEventTab0 = (props) => {

  return (
    <div className='charger-detail-tab tab2 chargerCardEventTab0'>
      <div>
        <div className='charger-detail-tab-selector-container'>
          <p className='charger-input-title'>충전기 모델</p>
          <div className="charger-detail-info-container chargerTab2 longer">
            <div>
              <p>{props.data?.ChargerModel?.Name}</p>
            </div>
          </div>
        </div>
        <div>
          <p className='charger-input-title'>기본 정보</p>
          <div className='charger-detail-info-flex-wrapper'>
            <div className="charger-detail-info-container chargerTab2 longer">
              <p>충전기 종류</p>
              <div>
                <p>{chargerTypeConverter(props.data?.ChargerModel?.ChargerType)}</p>
              </div>
            </div>
            <div className="charger-detail-info-container chargerTab2 longer">
              <p>커넥터 타입</p>
              <div className='charger-detail-info-connectorType'>
                {connectorTypeConverter(props.data?.ChargerModel?.ConnectorType)?.map((it)=>{
                  return(<p>{it}</p>);
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className='charger-input-title'>기기 정보</p>
        <div className='charger-model-info-grid'>
          <div className="charger-detail-info-container chargerTab2">
            <p>속도</p>
            <div>
              <p>{props.data?.ChargerModel?.ChargingSpeed}</p>
              <p className='postFix'>kWh</p>
            </div>
          </div>
          <div className="charger-detail-info-container chargerTab2">
            <p>출력</p>
            <div>
              <p>{props.data?.ChargerModel?.ChargingPower}</p>
              <p className='postFix'>kW</p>
            </div>
          </div>
          <div className="charger-detail-info-container chargerTab2">
            <p>전압</p>
            <div>
              <p>{props.data?.ChargerModel?.Voltage}</p>
              <p className='postFix'>V</p>
            </div>
          </div>
          <div className="charger-detail-info-container chargerTab2">
            <p>전류</p>
            <div>
              <p>{props.data?.ChargerModel?.ElectricCurrent}</p>
              <p className='postFix'>A</p>
            </div>
          </div>

          
        </div>
      </div>
      <div>
        <p className='charger-input-title'>상세 정보</p>
        <div className="charger-detail-info-container chargerTab2 longer">
          <p>등록년월</p>
          <div>
            <p>{props.data?.ChargerModel?.ActualRegistrationDate}</p>
          </div>
        </div>
        <div className="charger-detail-info-container chargerTab2 longer">
          <p>설명</p>
          <p>{props.data?.ChargerModel?.Remarks ? props.data?.ChargerModel?.Remarks:"-"}</p>
        </div>
        
      </div>
      <div>
        <div className="charger-detail-info-textarea-container">
          <p className='charger-input-title'>상세 스펙</p>
          <textarea value={props.data?.ChargerModel?.DetailedSpec} readOnly/>
        </div>
      </div>
    </div>
  )
}
export default ChargerCardEventTab0;

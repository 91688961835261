import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import { setCookie, getCookie } from '../../util/util';

import { TfiClose } from "react-icons/tfi";


export const CpoGroupInvitation = (props) => {
  const signedToken =  getCookie('signedToken')

  return (
    <div className="cpo-group-invitation-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-group-invitation-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-group-invitation-modal-header">
          <title>초대</title>
          <p>초대하고 싶은 상대방의 이메일을 입력하세요</p>
          <div className='cpo-group-invitation-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="cpo-group-invitation-modal-content">
          <div className="cpo-group-invitation-modal-content-wrapper marginTop">
            <p>상대방 이메일</p>
            <div className="cpo-group-invitation-modal-content-input-container">
              <input type="text" placeholder='상대방의 이메일을 입력하세요' />
            </div>
          </div>
        </div>
        <div className="cpo-group-invitation-modal-content-wrapper marginTop">
          <div className="cpo-group-invitation-modal-button-container">
            <div className="cpo-group-invitation-modal-button">
              초대
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}
export default CpoGroupInvitation

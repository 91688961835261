import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";

import {setNum} from '../../store.js'
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { getCookie } from '../../util/util.js';
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";


const CpoMemberInfoUpdate = (props) => {
  let navigator = useNavigate();

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");

  useEffect(()=>{
    dispatch(setNum({id:8, idx:2}));
  },[])



  const numberInput = (target)=>{
    target.value = target.value.replace(/[^0-9]/g, '');
  }


  const [name, setName] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordReCheck, setPasswordReCheck] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [keyNumber, setKeyNumber] = useState(null);



  return (
    <div className='cpo-member-info-update-container'>
        <div className='cpo-member-info-update-grid-container'>
            <div className='cpo-member-info-update-front'>

              <img className='member-info-update-background' alt="" />
              <img className='member-info-update-logo'/>

            </div>
            <div className='member-info-update-back-wrapper'>
              {
              <div className='cpo-member-info-update-input-list-container'>  
                <div className="cpo-member-info-update-input-outer">
                  <p>이름</p>
                  <div className="cpo-member-update-input-divide-wrapper">
                    <div className="cpo-member-info-update-input-container">
                      <input type="text" placeholder='이름을 입력해주세요'/>
                    </div>
                    <div className="cpo-member-info-update-input-button">중복확인</div>
                  </div>
                  {/* <p className='cpo-member-info-update-msg alert'>중복확인 버튼을 눌러주세요.</p> */}
                </div>
                <div className="cpo-member-info-update-input-outer">
                  <p>비밀번호 변경</p>
                  <div className="cpo-member-info-update-input-container">
                    <input type="text" placeholder='비밀번호를 입력해주세요'/>
                  </div>
                  <p className='cpo-member-info-update-msg'>대문자와 소문자, 숫자, 특수문자 중 2가지 이상을 조합하여 6~20자로 입력해주세요.</p>
                </div>
                <div className="cpo-member-info-update-input-outer">
                  <p>비밀번호 재확인</p>
                  <div className="cpo-member-info-update-input-container">
                    <input type="text" placeholder='비밀번호를 재확인해주세요'/>
                  </div>
                  <p className='cpo-member-info-update-msg'>위에서 입력한 새로운 비밀번호를 다시 입력해주세요.</p>
                </div>
                <div className="cpo-member-info-update-input-outer">
                  <p>핸드폰 번호 변경</p>
                  <div className="cpo-member-update-input-divide-wrapper">
                    <div className="cpo-member-info-update-input-container">
                      <input type="text" placeholder='핸드폰 번호를 입력해주세요'/>
                    </div>
                    <div className="cpo-member-info-update-input-button">본인인증</div>
                  </div>
                  {/* <p className='cpo-member-info-update-msg alert'>본인인증 버튼을 눌러주세요.</p> */}
                </div>
                <div className="cpo-member-info-update-input-outer">
                  <p>인증번호 입력</p>
                  <div className="cpo-member-update-input-divide-wrapper">
                    <div className="cpo-member-info-update-input-container">
                      <input type="text" placeholder='인증번호를 입력해주세요'/>
                    </div>
                    <div className="cpo-member-info-update-input-button">인증확인</div>
                  </div>
                  {/* <p className='cpo-member-info-update-msg alert'>잘못된 인증번호입니다.</p> */}
                </div>
              </div>
            }
            <div className="cpo-member-info-update-button-container">
              <div className={'cpo-member-info-update-button '} onClick={()=>{
              }}>
                <p>변경사항저장</p>
              </div>
            </div>
          </div>
        </div>
        
    </div>
  )
}
export default CpoMemberInfoUpdate;
import { useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'

import SearchSelectorNotCreatable from '../../components/buttons/SearchSelectorNotCreatable.js';

const ChargerCardEventTab3 = (props) => {

  const [fileList, setFileList] = useState(
    [
      {name: "EVW011SK-SD/0.0.0"},
      {name: "EVW011SK-SD/0.1.0"},
      {name: "EVW011SK-SD/0.1.2"},
      {name: "EVW011SK-SD/1.0.0"},
      {name: "EVW011SK-SD/1.0.2"},
    ]
  );


  const [labelList, setLabelList] = useState();

  const getLabelList = ()=>{
    let temp = [];
    fileList.map((it, idx)=>{
      let val = it.name;
      temp.push({value: val, label: val});
    })
    setLabelList(temp);
  }
  useEffect(()=>{
    getLabelList()
  },[])

  const [targetFile, setTargetFile]= useState();


  return (
    <>
      <div className='firmware-update-container chargerCardEventTab3'>
        <div className='firmware-update-add-button-container'>
          <div className='firmware-update-add-button' onClick={()=>{props.setModalOn(true)}}>펌웨어 파일추가</div>
        </div>
        <p className='firmware-update-title'>파일명/버전</p>
        <div className="firmware-update-selector-list-container">
          <div>
            <SearchSelectorNotCreatable placeholder="파일명을 입력 및 선택하세요" options={labelList} setValue={setTargetFile} />
          </div>
          <div className='firmware-update-apply-button'>적용</div>
        </div>
        <div className="firmware-update-update-list-container">
          <div className="firmware-update-update-list-column">
            <div>번호</div>
            <div>파일명</div>
            <div>버전</div>
            <div>요청일시</div>
            <div>요청계정</div>
          </div>
          <div className="firmware-update-update-list">
            <div className="firmware-update-update-list-item">
              <div><p>101</p></div>
              <div><p>EVW011SK-SD</p></div>
              <div><p>0.1.0</p></div>
              <div><p>2024.09.24</p></div>
              <div><p>lnamkyung</p></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ChargerCardEventTab3;

import React, { useRef, useState, useEffect } from 'react';
import { TbDotsVertical } from "react-icons/tb";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";

/*
[
    {
        "paymentCardId": "941ddb05-cc6a-47c6-ae85-3cc797730f84",
        "number": "53275011****451*",
        "name": "토스카드",
        "isDeleted": false,
        "isValid": true,
        "createdDate": "2024-08-01T19:09:55.835266",
        "modifiedDate": "2024-08-06T04:31:08.81727",
        "paymentCardStatus": "ENABLED",
        "paymentGatewayType": "TossPayments",
        "issuerCode": "24",
        "acquirerCode": "21",
        "cardType": "신용",
        "ownerType": "개인"
    }
]
*/
export const PaymentItem = (props) => {
  const[btnOpen, setBtnOpen] = useState(false);

  const clickFunction = (category)=>{
    let temp = {...props.data, category:category}
    props.setFunctionRequest(temp);
    props.setFunctionModalOn(true);
  }

  const getCardBrandImage = ()=>{
    switch (props.data.binInfo.brand) {

      case '마스터':
        return "mastercard";
      case '비자':
        return 'visa';
      case '아멕스':
        return "amex";
      case '다이너스':
        return "dinersclub";
      case 'JCB':
        return "jcb";
      case '은련':
        return 'unionpay';
      default:
        return 'local';
        break;
    }
  }

  return (
    <div className='cpo-payment-card-item-container'>
      <img className={`${getCardBrandImage()}`} alt="" />
      <p>{props.data.binInfo.issuer}</p>
      <p>{props.data.name}</p>
      <p>{props.data.binInfo.cardType}</p>
      <p>{"("+props.data.number+")"}</p>
      <p>{props.data.ownerType}</p>
      <div className={'cpo-payment-card-item-status ' +`${props.data.paymentCardStatus ? "":"disabled"}`}>
        <p>{props.data.paymentCardStatus}</p>
      </div>
      <div className="cpo-payment-card-item-function-button-container" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); setBtnOpen(!btnOpen)}}>
        <div className={"cpo-payment-card-item-function-button-list " + `${btnOpen ? "open":""}`}>
          <div>
            <div className="cpo-payment-card-item-function-button" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); clickFunction('edit');}}>
              <div><FiEdit3/></div>
            </div>
          </div>

          <div>
            <div className="cpo-payment-card-item-function-button delete" onClick={(e)=>{e.stopPropagation(); e.preventDefault();clickFunction("delete");}}>
              <div><FaRegTrashAlt/></div>
            </div>
          </div>

        </div>
        <TbDotsVertical/>
      </div>
    </div>
  )
}
export default PaymentItem;
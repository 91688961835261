import React,{useEffect, useState} from 'react'
import qrNavigatorBg from '../../img/qrNavigatorBg.svg'
import { FaAppStoreIos } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";

export const QrNavigator = () => {

  function checkMobile(){
    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
    if ( varUA.indexOf('android') > -1) {
        //안드로이드
        return "android";
    } else if ( varUA.indexOf("iphone") > -1||varUA.indexOf("ipad") > -1||varUA.indexOf("ipod") > -1 ) {
        //IOS
        return "ios";
    } else {
        //아이폰, 안드로이드 외
        return "other";
    }   
  }

  useEffect(()=>{
    // 주소 api 요청

  },[])

  const [storeUrl, setStoreUrl] = useState({
    android : 'https://play.google.com/store/apps/details?id=com.monit.evonboard.app&pcampaignid=web_share',
    ios: "https://apps.apple.com/kr/app/evonboard/id6455987115"
  });

  const goToStore = ()=>{
    let val = checkMobile();
    let url;
    switch (val) {
      case "android":
        url = storeUrl.android;
        break;
        case "ios":
        url = storeUrl.ios;
        break;
      case "other":
        return;
    
      default:
        break;
    }
    window.location.href(url)
  }

  useEffect(()=>{
    goToStore();
  },[])



  return (
    <>
      <div className='qr-navigator-bg'>
        <img src={qrNavigatorBg} alt="" />
        <div className='qr-navigator-title'>
          EMOOV App Download
        </div>
        <div className='store-button-container'>
          <a href={storeUrl.android} target='_blank'>
            <div className='store-button'>
              <img className='playStore' alt="" />
              <p>Android</p>
            </div>
          </a>
          <a href={storeUrl.ios} target='_blank'>
            <div className='store-button'><img className='appStore' alt="" />
            <p>iOS</p></div>
          </a>
        </div>
      </div>
    </>
  )
}
export default QrNavigator;
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { setCookie, getCookie } from '../../util/util';
import { CiWarning } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";


export const CpoInstallAdminEnrollModal = (props) => {
  const signedToken =  getCookie('signedToken')

  const [name, setName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [division, setDivision] = useState(null);
  const [role, setRole] = useState(null);
  
  
  return (
    <div className="cpo-install-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-install-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-install-enroll-modal-header">
          <title>담당자 추가</title>
          <div className='cpo-install-enroll-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="cpo-install-enroll-modal-content">
          <div>
            <div className="cpo-install-enroll-modal-content-wrapper">
              <p>이름</p>
              <div className="cpo-install-enroll-modal-content-input-container">
                <input type="text"  placeholder='이름' value={name} onChange={(e)=>{setName(e.target.value)}}/>
              </div>
            </div>
            <div className="cpo-install-enroll-modal-content-wrapper">
              <p>휴대전화번호</p>
              <div className="cpo-install-enroll-modal-content-input-container">
                <input type="text"  placeholder='휴대전화번호' value={phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}}/>
              </div>
            </div>
            <div className="cpo-install-enroll-modal-content-wrapper divide">
              <div>
                <p>부서 이름</p>
                <div className="cpo-install-enroll-modal-content-input-container">
                  <input type="text"  placeholder='부서 이름' value={division} onChange={(e)=>{setDivision(e.target.value)}}/>
                </div>
              </div>
              <div>
                <p>직책</p>
                <div className="cpo-install-enroll-modal-content-input-container">
                  <input type="text"  placeholder='직책' value={role} onChange={(e)=>{setRole(e.target.value)}}/>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className='cpo-install-enroll-modal-button-container'>
          <div className="cpo-install-enroll-modal-button">등록하기</div>
        </div>
      </div>
    </div>
  )
}
export default CpoInstallAdminEnrollModal

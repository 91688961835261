import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { setCookie, getCookie } from '../../util/util';

const signedToken =  getCookie('signedToken')


// cpo가 소유한 station GET
export const getStationList = async(id)=>{  
  await axios.get(`/infra/api/cpouser/v1/ChargingStations`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    let temp = [...res.data.Results.filter(it=>it.OpStatus!=="삭제됨")];
    return temp;
  })
  .catch(()=>{})
}


// charger 정보 API GET
export const getChargerList = async(stationList)=>{
  let temp = [];
  for await(const it of stationList){
    await axios.get(`/infra/api/cpouser/v1/Chargers?ChargingStationId=${it.ChargingStationId}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      temp.push(res.data);
    })
    .catch(()=>{})
  }
  return temp;
}

// charger 정보 API GET
export const getChargerInfo = async(id)=>{
  let temp;
  await axios.get(`/infra/api/cpouser/v1/Chargers/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
    temp = res.data;
  })
  .catch(()=>{})
  return temp;
}
// charger 삭제
export const deleteCharger = async(id)=>{
  const signedToken =  getCookie('signedToken')
  await axios.delete(`/infra/api/cpouser/v1/Chargers/${id}`,{
    headers: {
      Authorization: `Bearer ${signedToken}`
    }
  })
  .then((res)=>{
  })
  .catch(()=>{})
}
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { getCookie } from '../../util/util.js';
import { FaWonSign } from "react-icons/fa";
import Selector2 from '../buttons/Selector2.js';
import { useNavigate } from "react-router-dom";

export const SubscriptionEnrollModal = (props) => {  
  let signedToken = getCookie("signedToken");  
  const [subscriptionName, setsubscriptionName] = useState("");
  const [subscriptionSerial, setsubscriptionSerial] = useState("");
  let navigator = useNavigate();
  function onInput(target){
    target.value = target.value.replace(/[^a-zA-Z]/g, '');
  }
  // 결제 수단(카드)
  const [cardList, setCardList] = useState([]);
  // 결제 수단(카드) 호출
  const getCardList = async(id)=>{
    await axios.get(`/payment/api/cpouser/PaymentCards`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data);
      setCardList([...res.data]);
    })
  }
  useEffect(()=>{
    getCardList();
  },[])
  // 구독 요청
  const postSubscription = async()=>{
    // console.log(
    //   {
    //     "subscriptionProductId": props.data.subscriptionProductId,
    //     "billingType": select==0 ? "MONTHLY":"YEARLY",
    //     "paymentCardId": paymentMethodVal.paymentCardId,
    //   }
    // );
    await axios.post(`/subscription/api/cpoadmin/Subscriptions`,{
      "subscriptionProductId": props.data.subscriptionProductId,
      "billingType": select==0 ? "MONTHLY":"YEARLY",
      "paymentCardId": paymentMethodVal.paymentCardId,
    },{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      props.getCurrentSubscriptionList();
      props.setOnOff(false);
      
    })
    .catch((err)=>{
      console.log(err);
      props.setOnOff(false);
      props.setCurrentSubList(
        [{
          "currentBillingType": "MONTHLY",
          "lastBillingDate": "2024-08-06T04:31:08.959633",
          "lastBillingOrderId": "d75b1708-cf75-444d-ae6c-0d52ed8f0c32",
          "paymentCardId": "941ddb05-cc6a-47c6-ae85-3cc797730f84",
          "renewalStatus": true,
          "subscriptionId": "02e5a7fb-c7d3-4f9a-ba54-f7e74d7c0904",
          "subscriptionProduct": {
              "subscriptionProductId": "0d1bac71-47f8-43d4-907e-22e0373228aa",
              "name": "pro",
              "normalizedName": "PRO"
          },
          "endDate": "2024-09-06T00:00:00",
          "startDate": "2024-08-06T00:00:00",
          "cpo": {
              "cpoId": "c87afec3-a3fc-4abe-88c9-d6eacf23a901",
              "cpoName": "이남경",
              "serialNumber": "AC"
          }
        }]
      );
    })
  }
  let a = {
    "subscribible": true,
    "emoovFeatures": {
        "chargerLimit": 20
    },
    "monthlyPrice": 1000,
    "yearlyPrice": 10000,
    "subscriptionProductId": "0d1bac71-47f8-43d4-907e-22e0373228aa",
    "name": "pro",
    "normalizedName": "PRO"
  }
  const [select, setSelect] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethodVal, setPaymentMethodVal] = useState("");
  const [paymentMethodActive, setPaymentMethodActive] = useState(null);

  const activeReset = ()=>{
    setPaymentMethodActive(false);
  }

  const [hover, setHover] = useState(false);

  return (
    <div className="cpo-subscription-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-subscription-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-subscription-enroll-modal-header">
          <title>구독 결제</title>
        </div>
        <div className='cpo-subscription-enroll-modal-info-container'>
          <p>선택한 상품</p>
          <p>{props.data.normalizedName}</p>
        </div>
        <p>기간 선택</p>
        <div className="cpo-subscription-enroll-modal-period-container">
          <div className={`${select == 0 ? 'selected':""}`} onClick={()=>{setSelect(0)}}>
            <p>1개월</p>
            <div className={'cpo-subscription-enroll-modal-period-price '}>
              <p>{`${props.data.monthlyPrice}` + '원'}</p>
            </div>
            <p>매월 자동 결제</p>
          </div>
          <div className={`${select == 1 ? 'selected':""}`} onClick={()=>{setSelect(1)}}>
            <p>1년</p>
            <div className={'cpo-subscription-enroll-modal-period-price '}>
              <p>{`${props.data.yearlyPrice}`+'원'}</p>
            </div>
            <p>매년 자동 결제</p>
          </div>
        </div>
        <p>결제 수단 선택</p>
        <div className='cpo-subscription-enroll-modal-payment-method-container' onMouseOver={()=>{setHover(true)}} onMouseOut={()=>{setHover(false)}} >
          {
            cardList.length !== 0 ?
            <Selector2 maxCount={5} name={'name'} dataList={cardList} select={paymentMethodVal} setSelect={setPaymentMethodVal} active={paymentMethodActive} setActive={setPaymentMethodActive} activeReset={activeReset}  />
            :
            <div className={'cpo-subscription-enroll-modal-payment-none-msg '} onClick={()=>{navigator('/cpo/payment-method')}}>
              {hover ? <p>결제수단 등록하기</p>:<p>등록된 결제수단이 없습니다. 결제수단을 등록해주세요.</p>}
            </div>
          }
        </div>
        <div>
          <div></div>
          <div className={'cpo-subscription-modal-enroll-button '+ `${!(select == 0 || select == 1) || !cardList || !paymentMethodVal ?"none":""}`} onClick={()=>{postSubscription()}}><p>결제</p></div>
        </div>
      </div>
    </div>
  )
}
export default SubscriptionEnrollModal;
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import Selector2 from '../buttons/Selector2';

export const ChargerEnrollModal = (props) => {
  const [chargerName, setChargerName] = useState("");
  const [chargerSerial, setChargerSerial] = useState("");
  
  const [cpoId, setCpoId] = useState(null);
  useEffect(()=>{
    setCpoId(localStorage.getItem('cpoId'));
  },[])
  const [chargerOCCPContent, setChargerOCCPContent] = useState();

  // 선택자 클릭 여부
  const [occpActive, setOccpActive] = useState('');
  const [occpSelect, setOccpSelect] = useState('none');
  const occpList = [{val:"OCCP_1_6",name:"OCCP 1.6"},{val:"OCCP_2_0_1",name:"OCCP 2.0.1"}];
  const activeReset=()=>{
    setOccpActive(false);
  }
  const enrollReq = async()=>{
    if(cpoId == null)return;
    await axios.post(`/infra/api/Chargers`,
    { 
      chargingStationId : chargerName,
      chargePointSerialNumber: chargerName,
      protocol: chargerOCCPContent,
    },
    {
      headers: { 'content-type': 'application/json-patch+json' },
    }
    ).then((res)=>{
    })
  }


  return (
    <div className="cpo-charger-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-charger-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-charger-enroll-modal-header">
          <title>충전기 등록</title>
        </div>
        <div><p>충전기 일련번호</p></div>
        <div className="cpo-charger-enroll-modal-input-container">
          <input type="text" placeholder='' value={chargerName} onChange={(e)=>{ setChargerName(e.target.value)}} onClick={(e)=>{e.stopPropagation(); }}/>
        </div>
        <div><p>OCCP 프로토콜</p></div>
        <Selector2 name={'name'} dataList={occpList} select={occpSelect} setSelect={setOccpSelect} active={occpActive} setActive={setOccpActive} activeReset={activeReset}/>
        <div>
          <div></div>
          <div className={'cpo-charger-modal-enroll-button '+ `${chargerName == "" || chargerSerial.length!==3 ?"none":""}`} onClick={()=>{enrollReq()}}><p>등록</p></div>
        </div>
      </div>
    </div>
  )
}
export default ChargerEnrollModal

import { useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {setCookie, getCookie} from "../../util/util.js"

export const SignUpTab3 = (props) => {
  const resend = async()=>{
    await axios.get(`/account/api/NearCpoAuth/resend-confirmation-email?email=${props.email}`,
    {},{withCredentials : true})
    .then((res)=>{
      console.log(res);
    })
    .catch((err)=>{
    })
  }
  return (
    <>
      <div style={{textAlign: "center", fontSize: "1.5rem", marginTop:"100px"}}>
        가입 완료!
      </div>
      <div style={{textAlign: "center", fontSize: "1rem", marginTop:"50px"}}>
        {"<"+props.email+">"}로 인증 메일을 전송하였습니다. 인증 후 서비스를 이용해주세요!
      </div>
      <div className='email-verification-button-container'>
        <div className='email-verification-resend-button' onClick={()=>{resend()}}><p>재전송</p></div>
      </div>
    </>
  )
}
export default SignUpTab3;


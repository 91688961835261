import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import axios from 'axios';
import {setNum} from '../../store.js'
import { useNavigate, useLocation } from "react-router-dom";

import { getCookie, refresh } from '../../util/util.js';
import Selector2 from '../../components/buttons/Selector2.js';

import { FaRegQuestionCircle } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import { MdSearch } from "react-icons/md";

import {FaChevronUp, FaChevronRight,  FaChevronDown, FaChevronLeft} from "react-icons/fa6";


export const CpoChasEdit = (props) => {
  let navigator = useNavigate();

  // redux
  let page = useSelector((state) => state.page );
  let dispatch = useDispatch();
  let signedToken = getCookie("signedToken");
  const location = useLocation();
  const data = location.state.data;
  const message = location.state.message;
  useEffect(()=>{
    dispatch(setNum({id:7, idx:1, detail:true}));
  },[])

  const messageTypeList=[
    {name:"AUTHORIZE_REQ"},
    {name:"START_TRANSACTION_REQ"},
    {name:"STOP_TRANSACTION_REQ"},
    {name:"REMOTE_START_TRANSACTION_REQ"},
    {name:"REMOTE_STOP_TRANSACTION_REQ"},
  ]


  const [messageTypeSelect, setMessageTypeSelect] = useState();
  const [messageTypeActive, setMessageTypeActive] = useState();
  const activeReset = async()=>{
    setMessageTypeActive(false);
  }

  useEffect(()=>{
    if(message){
      let val = messageTypeList.filter(it=>it.name == message)[0]
      setMessageTypeSelect(val);
    }
  },[message])
  const [chasDataModalOn, setChasDataModalOn] = useState(false);


  // /api/cpouser/v1/ActionSets/{data?.actionSetId}/AUTHORIZE_REQ --메세지 추가

  // /controlhub/api/ControlHubActions --액션들 불러오기

  const [actionList, setActionList] = useState();
  const [actionOfMessageList, setActionOfMessageList] = useState();
  const [actionOrderList, setActionOrderList] = useState();
  const getActions = async()=>{
    axios.get('/controlhub/api/ControlHubActions',{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res);
      setActionList(res.data);
    })
  }

  const getActionOfMessageList = async()=>{
    if(!messageTypeSelect?.name)return;
    axios.get(`/controlhub/api/cpouser/v1/ActionSets/${data?.actionSetId}/${messageTypeSelect?.name}`,{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res.data);
      setActionOfMessageList(res.data);
    })
  }

  const putActionOfMessageList = async(orderList)=>{
    if(!messageTypeSelect?.name)return;
    axios.put(`/controlhub/api/cpouser/v1/ActionSets/${data?.actionSetId}/${messageTypeSelect?.name}`,{
      "controlHubActionInfoIds": orderList
    },{
      headers: {
        Authorization: `Bearer ${signedToken}`
      }
    })
    .then((res)=>{
      console.log(res);
      getActionOfMessageList();
    })
  }

  


  useEffect(()=>{
    getActions();
  },[])

  useEffect(()=>{
    getActionOfMessageList();
  },[messageTypeSelect?.name])

  useEffect(()=>{
    if(!actionOfMessageList)return;
    let temp = [...actionOfMessageList];
    let temp2 = temp.map((it, idx)=>{
      return(it.chControlHubAction.controlHubActionId)
    })
    console.log(temp2);
    setActionOrderList(temp2);
  },[actionOfMessageList])


  const [rightSelected, setRightSelected] = useState(null);
  const [leftSelected, setLeftSelected] = useState(null);

  // 버튼 활성화 state
  const [moveTop, setMoveTop] = useState(false);
  const [moveRight, setMoveRight] = useState(false);
  const [moveLeft, setMoveLeft] = useState(false);
  const [moveBottom, setMoveBottom] = useState(false);

  useEffect(()=>{
    if(rightSelected !== null){
      setMoveRight(false);
      setMoveLeft(true);
      if(rightSelected == 0){
        setMoveTop(false);
        setMoveBottom(true);
      }
      else if(rightSelected == actionOfMessageList.length-1){
        setMoveBottom(false);
        setMoveTop(true);
      }
      else{
        setMoveTop(true);
        setMoveBottom(true);
      }
    }
    else if(leftSelected !== null){
      setMoveRight(true);
      setMoveLeft(false);
      setMoveTop(false);
      setMoveBottom(false);
    }
  },[rightSelected, leftSelected])

  const moveClick = async(direction)=>{
    if(direction == "top"){
      let temp = [...actionOrderList];
      putActionOfMessageList(moveElement(temp, rightSelected, rightSelected-1));
      setRightSelected(rightSelected-1);
    }
    else if(direction == "bottom"){
      let temp = [...actionOrderList];
      putActionOfMessageList(moveElement(temp, rightSelected, rightSelected+1));
      setRightSelected(rightSelected+1);
    }
    else if(direction == "left"){
      let temp = [...actionOrderList];
      temp.splice(rightSelected,1);
      putActionOfMessageList(temp);
      if(rightSelected == temp.length)setRightSelected(temp.length-1)
      if(temp.length == 0){
        setRightSelected(null)
        setMoveRight(false);
        setMoveLeft(false);
        setMoveTop(false);
        setMoveBottom(false);
      }
    }
    else if(direction == "right"){ // 현재 액션리스트로 추가
      let temp = [...actionOrderList, actionList[leftSelected].controlHubActionId];
      setActionOrderList(temp);
      putActionOfMessageList(temp);
      setMoveRight(false);
      setMoveLeft(false);
      setMoveTop(false);
      setMoveBottom(false);
    }
  }

  const moveElement = (arr, fromIndex, toIndex) =>{
    const element = arr.splice(fromIndex, 1)[0]; // 원소를 잘라내기
    arr.splice(toIndex, 0, element); // 원하는 위치에 삽입
    return arr;
  }

  return (
    <div className='cpo-system-setting-container'>
      
      <div className="cpo-system-setting-inner chas">
      <div className="cpo-chas-content-container">
        <div className='cpo-chas-navigator' onClick={()=>{navigator("/cpo/chas")}}>
          <FaChevronLeft/>뒤로가기
        </div>
        <div className='cpo-chas-content-header'>
          <div className='cpo-chas-data-container'><p>CHAS DATA</p><FaRegQuestionCircle onClick={()=>{setChasDataModalOn(!chasDataModalOn);}}/></div>
          {(chasDataModalOn)?
            <div className='cpo-chas-data-modal edit'>
              <p>{data?.description}</p>
              <p>{`${data?.createdDate} / ${data?.lastModifiedDate} / ${'1.0.0'} / ${'0.0.0'}`}</p>
            </div>
            :<></>
          }

          <div className='cpo-chas-title-container'>
            <p>{data?.name}</p>
            <p className='cpo-chas-edit-info'>일반 메세지 편집</p>
          </div>
        </div>

        <div className="cpo-chas-edit-message-selector-block-container">
          <p>메세지 유형 선택</p>
          <div className='cpo-chas-edit-message-selector-wrapper'>
            <div className='cpo-chas-edit-message-selector-container'>
              <Selector2 name={'name'} dataList={messageTypeList} select={messageTypeSelect} setSelect={setMessageTypeSelect} active={messageTypeActive} setActive={setMessageTypeActive} activeReset={activeReset}/>
            </div>
          </div>
          <p>메세지 유형을 선택한 다음 액션을 추가할 수 있습니다.</p>
        </div>
        <div className='cpo-chas-action-edit-block-container'>
          <div className='cpo-chas-title-container edit'>
            <p>{data?.name}</p>
            <FaChevronRight/>
            <p>{messageTypeSelect?.name}</p>
          </div>
        </div>
        <div className='cpo-chas-action-grid-container'>
          <div className='cpo-chas-action-edit-container'>
            <div className='cpo-chas-action-edit-title-container'>Actions</div>
            <div className='cpo-chas-action-edit-search-container'>
              <MdSearch/>
              <input type="text" placeholder='키워드로 검색하세요'/>
            </div>
            <div className='cpo-chas-action-edit-item-list'>
            {
              actionList?.map((it, idx)=>{
                if(actionOfMessageList?.filter(action=>action?.chControlHubAction?.name == it.name).length !== 0)return;
                return(
                  <>
                    <div className={'cpo-chas-action-edit-item left '+`${leftSelected==idx?"selected":""}`} onClick={()=>{setLeftSelected(idx); setRightSelected(null) }}>{it.name}</div>
                  </>
                )
              })
            }
            </div>
          </div>
          <div className='cpo-chas-action-edit-four-button-container'>
            <div className={'cpo-chas-action-edit-move-button ' + `${moveTop ? "activate" : ""}` } onClick={()=>{moveClick('top')}}><FaChevronUp/></div>
            <div className={'cpo-chas-action-edit-move-button ' + `${moveRight ? "activate" : ""}` } onClick={()=>{moveClick('right')}}><FaChevronRight/></div>
            <div className={'cpo-chas-action-edit-move-button ' + `${moveLeft ? "activate" : ""}` } onClick={()=>{moveClick('left')}}><FaChevronLeft/></div>
            <div className={'cpo-chas-action-edit-move-button ' + `${moveBottom ? "activate" : ""}` } onClick={()=>{moveClick('bottom')}}><FaChevronDown/></div>
          </div>
          <div className='cpo-chas-action-edit-container'>
            <div className='cpo-chas-action-edit-title-container'>Binded Action</div>
            <div className='cpo-chas-action-edit-search-container'>
              <MdSearch/>
              <input type="text" placeholder='키워드로 검색하세요'/>
            </div>
            <div className='cpo-chas-action-edit-item-list'>
            {
              actionOfMessageList?.map((it, idx)=>{
                return(
                  <>
                    <div className={'cpo-chas-action-edit-item right '+`${rightSelected==idx?"selected":""}`} onClick={()=>{setRightSelected(idx); setLeftSelected(null);}}>
                      <p>{it.order}</p>
                      <p>{it.chControlHubAction?.name}</p>
                    </div>
                  </>
                )
              })
            }
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}
export default CpoChasEdit;
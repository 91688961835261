import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import { setCookie, getCookie } from '../../util/util';

import { TfiClose } from "react-icons/tfi";

import UploadBox from "../buttons/UploadBox.js"

export const CpoAppendFirmWareFileModal = (props) => {
  const signedToken =  getCookie('signedToken')
  const [file, setFile] = useState();
  return (
    <div className="cpo-append-firmware-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-append-firmware-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-append-firmware-modal-header">
          <title>펌웨어 파일추가</title>
          <p>zip 형식의 펌웨어 파일을 업로드하세요</p>
          <div className='cpo-append-firmware-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <p>파일</p>
        <div className="cpo-append-firmware-modal-upload-box-container">
          <UploadBox setFile={setFile}/>
        </div>
        <p>버전</p>
        <div className='cpo-append-firmware-modal-input-container'>
          <input type="text" placeholder='버전을 입력하세요'/>
        </div>
        <div className='cpo-append-firmware-button-list-container'>
          <div className="cpo-append-firmware-button cancel" onClick={()=>{props.setOnOff(false);}}>취소</div>
          <div className="cpo-append-firmware-button apply">적용</div>
        </div>
      </div>
    </div>
  )
}
export default CpoAppendFirmWareFileModal

import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaCaretDown } from "react-icons/fa";
import { setCookie, getCookie } from '../../util/util';
import { CiWarning } from "react-icons/ci";
import { TfiClose } from "react-icons/tfi";
import Selector2 from '../buttons/Selector2';

export const CpoInstallEnrollModal = (props) => {
  const signedToken =  getCookie('signedToken')

  const [companyName, setCompanyName] = useState(null);
  const [companyCategory, setCompanyCategory] = useState(null);
  const [name, setName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [division, setDivision] = useState(null);
  const [role, setRole] = useState(null);
  
  const [companyCategoryActive, setCompanyCategoryActive] = useState();
  const activeReset = ()=>{
    setCompanyCategoryActive(false)
  }
  let companyCategoryList=[
  {name:"충전기설치"},
  {name:"캐노피설치"},
  {name:"윈치설치"},
  {name:"충전기제조사"}
  ];
  return (
    <div className="cpo-install-enroll-modal-bg" onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}>
      <div className="cpo-install-enroll-modal-container"  onClick={(e)=>{e.stopPropagation(); e.preventDefault();}}>
        <div className="cpo-install-enroll-modal-header">
          <title>업체 등록</title>
          <div className='cpo-install-enroll-modal-close-button'  onClick={(e)=>{e.stopPropagation(); e.preventDefault(); props.setOnOff(false);}}><TfiClose/></div>
        </div>
        <div className="cpo-install-enroll-modal-content">
          <div>
            <div className="cpo-install-enroll-modal-content-header">
              <p className="cpo-install-enroll-modal-content-title">업체 정보</p>
            </div>
            <div className='cpo-install-enroll-modal-content-wrapper'>
              <p>업체 이름</p>
              <div className="cpo-install-enroll-modal-content-input-container">
                <input type="text" placeholder='업체 이름을 입력해주세요' value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}} />
              </div>
            </div>
            <div className='cpo-install-enroll-modal-content-wrapper'>
              <p>업체 분야</p>
              <div className="cpo-install-enroll-modal-content-selector-container">
                <Selector2 name={'name'} dataList={companyCategoryList} select={companyCategory} setSelect={setCompanyCategory} active={companyCategoryActive} setActive={setCompanyCategoryActive} activeReset={activeReset}/>
              </div>
            </div>
          </div>
          
          <div>
            <div className="cpo-install-enroll-modal-content-header">
              <p className="cpo-install-enroll-modal-content-title">담당자 정보</p>
              <p className='cpo-install-enroll-modal-content-desc'>등록 이후에도 담당자 추가/수정이 가능합니다</p>
            </div>
            <div className="cpo-install-enroll-modal-content-wrapper">
              <p>이름</p>
              <div className="cpo-install-enroll-modal-content-input-container">
                <input type="text"  placeholder='이름' value={name} onChange={(e)=>{setName(e.target.value)}}/>
              </div>
            </div>
            <div className="cpo-install-enroll-modal-content-wrapper">
              <p>휴대전화번호</p>
              <div className="cpo-install-enroll-modal-content-input-container">
                <input type="text"  placeholder='휴대전화번호' value={phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}}/>
              </div>
            </div>
            <div className="cpo-install-enroll-modal-content-wrapper divide">
              <div>
                <p>부서 이름</p>
                <div className="cpo-install-enroll-modal-content-input-container">
                  <input type="text"  placeholder='부서 이름' value={division} onChange={(e)=>{setDivision(e.target.value)}}/>
                </div>
              </div>
              <div>
                <p>직책</p>
                <div className="cpo-install-enroll-modal-content-input-container">
                  <input type="text"  placeholder='직책' value={role} onChange={(e)=>{setRole(e.target.value)}}/>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className='cpo-install-enroll-modal-button-container'>
          <div className="cpo-install-enroll-modal-button">등록하기</div>
        </div>
      </div>
    </div>
  )
}
export default CpoInstallEnrollModal
